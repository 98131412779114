import { useCallback, useState } from 'react'

const getLocalStorageState = <T>(
    storageKey: string,
    defaultValue: T | undefined
) => {
    const value = localStorage[storageKey] ?? 'null'

    try {
        return (JSON.parse(value) as T | undefined) ?? defaultValue
    } catch {
        return defaultValue
    }
}

function useLocalStorageState<T>(
    initialValue: T,
    storageKey: string
): [T, (value: T | undefined) => void]
function useLocalStorageState<T = undefined>(
    initialValue: undefined,
    storageKey: string
): [T | undefined, (value: T | undefined) => void]
function useLocalStorageState<T>(
    initialValue: T | undefined,
    storageKey: string
): [T | undefined, (value: T | undefined) => void] {
    const [value, setValue] = useState(
        getLocalStorageState(storageKey, initialValue)
    )

    const setStorageValue = useCallback(
        (value: T | undefined) => {
            localStorage[storageKey] = JSON.stringify(value)
            setValue(value)
        },
        [storageKey, value]
    )

    return [value, setStorageValue] as const
}

export { useLocalStorageState }
