import { BannerType } from '../types/enums/bannerType.enum.ts'

import { CreateOrUpdateBanner } from '../types/bannerTypes'

export type EmptyBanner = Omit<CreateOrUpdateBanner, 'backgroundMediaId'>

export const createEmptyBanner = (): EmptyBanner => ({
    name: '',
    title: {
        en: '',
    },
    subtitle: null,
    activeFromDate: '2025-03-19T00:00:00.000Z',
    activeToDate: '2025-03-19T00:00:00.000Z',
    type: BannerType.MOON,
    isDefault: false,
    actionType: 'open_shop',
    actionParameters: null,
    positionScene: 'start_screen',
    positionPlacement: 'top_left',
})
