import { QueryFunction, skipToken, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { LanguageType } from '../types/enums/languagetype.enum'

const TRANSLATIONS_PATH = '/api/v2/translations'

export type TranslationTable = Record<LanguageType, string>
export type Translations = Record<string, Record<string, string>>

const getTranslations: QueryFunction<
    Translations,
    [string, string, string | undefined]
> = async ({ queryKey: [url, range, stringId], signal }) => {
    if (stringId === undefined) {
        return skipToken
    }

    const { data } = await axios.get(
        `${url}?range=${range}&stringId=${stringId}`,
        {
            signal,
        }
    )

    return data
}

export const useTranslations = (range: string, stringId?: string) => {
    return useQuery({
        queryKey: [TRANSLATIONS_PATH, range, stringId],
        queryFn: getTranslations,
    })
}
