import { useMemo, useState } from 'react'
import _ from 'lodash'

import IconAddPlus from '../../icons/IconAddPlus.svg'
import IconEye from '../../icons/IconEye.svg'
import { Button } from '../../shared/Button'
import { PortalPublicationFeedTable } from './PortalPublicationFeedTable'
import { SelectPostModal, SelectPostModalProps } from './SelectPostModal'
import { Loading } from '../../shared'

import { PutScheduleDto, Schedule, usePortalSchedule } from '../../hooks/portal'

import styles from './PortalPublicationFeed.module.scss'

export const PortalPublicationFeed = () => {
    const { data: schedule, savePortalSchedule } = usePortalSchedule()

    const saveSchedule = (schedule: Schedule) => {
        const putScheduleDto: PutScheduleDto = {
            publications: schedule.publications.map((publication) => ({
                portalPostId: publication.post.id,
                startTime: publication.startTime,
                endTime: publication.endTime,
                position: publication.position,
            })),
        }

        return savePortalSchedule.mutateAsync(putScheduleDto)
    }

    if (!schedule) {
        return <Loading />
    }

    return (
        <PortalPublicationFeedInternal
            initialSchedule={schedule}
            saveSchedule={saveSchedule}
            isSaving={savePortalSchedule.isPending}
        />
    )
}

const PortalPublicationFeedInternal = (props: {
    initialSchedule: Schedule
    saveSchedule?: (schedule: Schedule) => Promise<Schedule>
    isSaving?: boolean
}) => {
    const [schedule, setSchedule] = useState(props.initialSchedule)
    const [isSelectPostModalOpen, setIsSelectPostModalOpen] = useState(false)

    const onPostsSelected: SelectPostModalProps['onPostsSelected'] = (
        posts,
        startTime,
        endTime
    ) => {
        setIsSelectPostModalOpen(false)

        const maxPosition = schedule.publications.reduce(
            (max, publication) => Math.max(max, publication.position),
            0
        )
        const newPublications: Schedule['publications'] = posts.map(
            (post, index) => ({
                id: crypto.randomUUID(),
                post,
                startTime: startTime.toISOString(),
                endTime: endTime?.toISOString() ?? null,
                position: maxPosition + index + 1,
            })
        )

        setSchedule(({ publications }) => {
            return {
                publications: [...publications, ...newPublications],
            }
        })
    }

    const hasChanges = useMemo(
        () => !_.isEqual(schedule, props.initialSchedule),
        [schedule, props.initialSchedule]
    )

    const saveChanges = () => {
        if (!hasChanges) {
            return
        }

        props.saveSchedule?.(schedule).then(setSchedule)
    }

    const setStartTime = (publicationId: string, startTime: Date) => {
        setSchedule(({ publications }) => ({
            publications: publications.map((publication) =>
                publication.id === publicationId
                    ? {
                          ...publication,
                          startTime: startTime.toISOString(),
                      }
                    : publication
            ),
        }))
    }

    const setEndTime = (publicationId: string, endTime: Date | null) => {
        setSchedule(({ publications }) => ({
            publications: publications.map((publication) =>
                publication.id === publicationId
                    ? {
                          ...publication,
                          endTime: endTime?.toISOString() ?? null,
                      }
                    : publication
            ),
        }))
    }

    const reorderPublication = (fromIndex: number, toIndex: number) => {
        const newPublications = _.cloneDeep(schedule.publications)

        const dragged = newPublications.splice(fromIndex, 1)[0]
        newPublications.splice(toIndex, 0, dragged)

        newPublications.forEach((value, index) => {
            value.position = index
        })

        setSchedule((oldSchedule) => ({
            ...oldSchedule,
            publications: newPublications,
        }))
    }

    return (
        <>
            <aside className={styles.createButtonContainer}>
                <Button
                    className={styles.createButton}
                    startContent={<img src={IconAddPlus} />}
                    onClick={() => setIsSelectPostModalOpen(true)}
                >
                    Add post to feed
                </Button>
            </aside>

            {isSelectPostModalOpen && (
                <SelectPostModal
                    onClose={() => setIsSelectPostModalOpen(false)}
                    onPostsSelected={onPostsSelected}
                />
            )}

            <PortalPublicationFeedTable
                schedule={schedule}
                onStartTimeChange={setStartTime}
                onEndTimeChange={setEndTime}
                onOrderChanged={reorderPublication}
            />
            {schedule.publications.length === 0 && (
                <div className={styles.empty}>
                    <p>Nothing in the feed is scheduled</p>
                    <Button
                        className="primary"
                        onClick={() => setIsSelectPostModalOpen(true)}
                    >
                        Schedule your first post
                    </Button>
                </div>
            )}

            <aside className={styles.saveMenu}>
                <p>{hasChanges ? 'Unsaved Changes' : 'No Changes'}</p>
                <Button
                    color="secondary"
                    startContent={<img src={IconEye} />}
                    disabled
                >
                    Preview Feed
                </Button>
                <Button
                    color="primary"
                    disabled={!hasChanges}
                    loading={props.isSaving}
                    onClick={saveChanges}
                >
                    Save
                </Button>
            </aside>
        </>
    )
}
