import { ReactNode, RefObject, useMemo, useRef } from 'react'
import {
    DragDropContext,
    Draggable,
    Droppable,
    OnDragEndResponder,
} from '@hello-pangea/dnd'
import _ from 'lodash'

import { Button } from '../../shared/Button'
import { Chip } from '../../shared/Chip'
import { TableColumn } from '../../shared/Table/TableColumn'

import { Publication, Schedule, usePortalSchedule } from '../../hooks/portal'
import { ComputedSize, useComputedSizes } from '../../hooks/utils'

import { toHTMLDate } from '../../utils/dates'

import { getPublicationStatus } from '../portalUtils'
import styles from './PortalPublicationFeedTable.module.scss'

export type PublicationRowColumns =
    | 'position'
    | 'name'
    | 'templateName'
    | 'start'
    | 'end'
    | 'status'
    | 'actions'

interface PortalPublicationFeedTableProps {
    schedule: Schedule
    onStartTimeChange?: (id: string, time: Date) => void
    onEndTimeChange?: (id: string, time: Date | null) => void
    onOrderChanged?: (fromIndex: number, toIndex: number) => void
}
export const PortalPublicationFeedTable = ({
    schedule,
    onStartTimeChange,
    onEndTimeChange,
    onOrderChanged,
}: PortalPublicationFeedTableProps) => {
    const { data: backendSchedule } = usePortalSchedule()

    const refs: Record<
        PublicationRowColumns,
        RefObject<HTMLTableCellElement>
    > = {
        position: useRef<HTMLTableCellElement | null>(null),
        name: useRef<HTMLTableCellElement | null>(null),
        templateName: useRef<HTMLTableCellElement | null>(null),
        start: useRef<HTMLTableCellElement | null>(null),
        end: useRef<HTMLTableCellElement | null>(null),
        status: useRef<HTMLTableCellElement | null>(null),
        actions: useRef<HTMLTableCellElement | null>(null),
    }
    const { computedSizes, getComputedSizes } = useComputedSizes(refs)

    const onDragEnd: OnDragEndResponder = (result) => {
        if (result.destination === null) {
            return
        }

        const fromIndex = result.source.index
        const toIndex = result.destination.index

        onOrderChanged?.(fromIndex, toIndex)
    }

    return (
        <DragDropContext
            onDragEnd={onDragEnd}
            onBeforeDragStart={getComputedSizes}
        >
            <Droppable droppableId="publications">
                {(droppableProvided) => (
                    <table
                        className={styles.table}
                        ref={droppableProvided.innerRef}
                    >
                        <thead>
                            <tr>
                                <TableColumn ref={refs.position}>
                                    Position
                                </TableColumn>
                                <TableColumn ref={refs.name}>Name</TableColumn>
                                <TableColumn ref={refs.templateName}>
                                    Post Type
                                </TableColumn>
                                <TableColumn ref={refs.start}>
                                    Start
                                </TableColumn>
                                <TableColumn ref={refs.end}>End</TableColumn>
                                <TableColumn ref={refs.status}>
                                    Status
                                </TableColumn>
                                <TableColumn ref={refs.actions}>
                                    {/*Actions*/}
                                </TableColumn>
                            </tr>
                        </thead>
                        <tbody>
                            {schedule.publications
                                .toSorted((a, b) => a.position - b.position)
                                .map((publication, index) => {
                                    return (
                                        <PublicationRow
                                            key={publication.id}
                                            publication={publication}
                                            index={index}
                                            columnSizes={computedSizes}
                                            onStartTimeChange={(time) =>
                                                onStartTimeChange?.(
                                                    publication.id,
                                                    time
                                                )
                                            }
                                            onEndTimeChange={(time) =>
                                                onEndTimeChange?.(
                                                    publication.id,
                                                    time
                                                )
                                            }
                                            status={
                                                backendSchedule ? (
                                                    <PublicationStatus
                                                        originalSchedule={
                                                            backendSchedule
                                                        }
                                                        publication={
                                                            publication
                                                        }
                                                    />
                                                ) : null
                                            }
                                        />
                                    )
                                })}
                            {droppableProvided.placeholder}
                        </tbody>
                    </table>
                )}
            </Droppable>
        </DragDropContext>
    )
}

interface PublicationStatusProps {
    originalSchedule: Schedule
    publication: Publication
}
const PublicationStatus = ({
    originalSchedule,
    publication,
}: PublicationStatusProps) => {
    const originalScheduleMap = useMemo(() => {
        return new Map(
            originalSchedule?.publications.map((publication) => [
                publication.id,
                publication,
            ]) ?? []
        )
    }, [originalSchedule?.publications])

    const isNew = !originalScheduleMap.has(publication.id)
    const isModified =
        !isNew &&
        !_.isEqual(publication, originalScheduleMap.get(publication.id))

    const publicationStatus = getPublicationStatus(publication)

    if (publicationStatus === 'expired') {
        return <Chip color="danger">Expired</Chip>
    }
    if (isNew) {
        return <Chip color="positive">Added</Chip>
    }
    if (isModified) {
        return <Chip color="primary">Modified</Chip>
    }
    return (
        <>
            {publicationStatus === 'scheduled' && (
                <Chip color="secondary">Scheduled</Chip>
            )}
            {publicationStatus === 'published' && (
                <Chip color="primary">Published</Chip>
            )}
        </>
    )
}

interface PublicationRowProps {
    publication: Schedule['publications'][number]
    status: ReactNode
    index: number
    columnSizes: RefObject<Record<PublicationRowColumns, ComputedSize>>
    onStartTimeChange?: (date: Date) => void
    onEndTimeChange?: (date: Date | null) => void
}

const PublicationRow = ({
    publication,
    status,
    index,
    columnSizes,
    onStartTimeChange,
    onEndTimeChange,
}: PublicationRowProps) => {
    const getStyle = (isDragging: boolean, column: PublicationRowColumns) => {
        const dragStyle = columnSizes.current?.[column] ?? {}
        return isDragging ? dragStyle : {}
    }

    return (
        <Draggable
            key={publication.id}
            draggableId={publication.id}
            index={index}
        >
            {(provided, snapshot) => (
                <tr
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    style={{
                        background: snapshot.isDragging ? '#EFF8FB' : undefined,
                        ...provided.draggableProps.style,
                    }}
                >
                    <td style={getStyle(snapshot.isDragging, 'position')}>
                        #{index + 1}
                    </td>
                    <td style={getStyle(snapshot.isDragging, 'name')}>
                        {publication.post.id}
                    </td>
                    <td style={getStyle(snapshot.isDragging, 'templateName')}>
                        {publication.post.templateId}
                    </td>
                    <td style={getStyle(snapshot.isDragging, 'start')}>
                        <input
                            title="Start Time"
                            type="datetime-local"
                            value={toHTMLDate(publication.startTime)}
                            onChange={(e) => {
                                if (e.target.value.length > 0) {
                                    onStartTimeChange?.(
                                        new Date(e.target.value)
                                    )
                                }
                            }}
                            required
                        />
                    </td>
                    <td style={getStyle(snapshot.isDragging, 'end')}>
                        <input
                            title="End Time"
                            type="datetime-local"
                            value={toHTMLDate(publication.endTime ?? undefined)}
                            onChange={(e) => {
                                onEndTimeChange?.(
                                    e.target.value
                                        ? new Date(e.target.value)
                                        : null
                                )
                            }}
                        />
                    </td>
                    <td style={getStyle(snapshot.isDragging, 'status')}>
                        {status}
                    </td>
                    <td style={getStyle(snapshot.isDragging, 'actions')}>
                        <div className={styles.actionsCell}>
                            <Button
                                color="secondary"
                                onClick={() => onEndTimeChange?.(new Date())}
                            >
                                Unpublish
                            </Button>
                            <div
                                {...provided.dragHandleProps}
                                className="dragHandle"
                            >
                                ≡
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </Draggable>
    )
}
