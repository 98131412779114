import { components } from '@lifeserver/models/lifev2'
import {
    skipToken,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query'
import axios from 'axios'

export type Post = components['schemas']['PortalPostDTO']
export type Schedule = components['schemas']['ScheduleDTO']
export type Publication =
    components['schemas']['ScheduleDTO']['publications'][number]
export type PutScheduleDto = components['schemas']['PutScheduleDTO']

const PORTAL_URL = '/api/v2/portal'
const PORTAL_POST_URL = `${PORTAL_URL}/post`
const PORTAL_SCHEDULE_URL = `${PORTAL_URL}/schedule`

const portalApi = {
    fetchPostById: async (id: string): Promise<Post> => {
        const { data } = await axios.get<Post>(`${PORTAL_POST_URL}/${id}`)

        return data
    },

    fetchPosts: async (): Promise<Array<Post>> => {
        const { data } = await axios.get<Array<Post>>(PORTAL_POST_URL)

        return data
    },

    createPost: async (
        createPostDto: components['schemas']['CreatePortalPostDTO']
    ): Promise<Post> => {
        const response = await axios.post<Post>(PORTAL_POST_URL, createPostDto)

        return response.data
    },

    deletePost: async (postId: string): Promise<void> => {
        await axios.delete(`${PORTAL_POST_URL}/${postId}`)
    },

    updatePost: async ({
        postId,
        updatePostDto,
    }: {
        postId: string
        updatePostDto: components['schemas']['UpdatePortalPostDTO']
    }): Promise<Post> => {
        const response = await axios.put<Post>(
            `${PORTAL_POST_URL}/${postId}`,
            updatePostDto
        )

        return response.data
    },

    getSchedule: async (): Promise<Schedule> => {
        const response = await axios.get<Schedule>(PORTAL_SCHEDULE_URL)

        return response.data
    },

    saveSchedule: async (putScheduleDto: PutScheduleDto): Promise<Schedule> => {
        const response = await axios.put<Schedule>(
            PORTAL_SCHEDULE_URL,
            putScheduleDto
        )

        return response.data
    },
}

export const usePortalPost = (id?: string) => {
    return useQuery({
        queryKey: [PORTAL_POST_URL, id],
        queryFn: id ? () => portalApi.fetchPostById(id) : skipToken,
    })
}

export const useCreatePortalPost = () => {
    const client = useQueryClient()

    return useMutation({
        mutationFn: portalApi.createPost,
        onMutate: async (newPost) => {
            await client.cancelQueries({ queryKey: [PORTAL_POST_URL] })
            const previousPosts =
                client.getQueryData<Array<Post>>([PORTAL_POST_URL]) ?? []
            const newPosts = [...previousPosts, newPost]
            client.setQueryData([PORTAL_POST_URL], newPosts)

            return { previousPosts, newPosts }
        },
        onError: (_, __, context) => {
            client.setQueryData([PORTAL_POST_URL], context?.previousPosts)
        },
        onSettled: () => {
            client.invalidateQueries({ queryKey: [PORTAL_POST_URL] })
        },
    })
}

export const useUpdatePortalPost = () => {
    const client = useQueryClient()

    return useMutation({
        mutationFn: portalApi.updatePost,
        onMutate: async ({ postId, updatePostDto }) => {
            await client.cancelQueries({ queryKey: [PORTAL_POST_URL] })
            const previousPosts =
                client.getQueryData<Array<Post>>([PORTAL_POST_URL]) ?? []
            const newPosts = previousPosts.map((post) =>
                post.id === postId ? updatePostDto : post
            )
            client.setQueryData([PORTAL_POST_URL], newPosts)

            return { previousPosts, newPosts }
        },
        onError: (_, __, context) => {
            client.setQueryData([PORTAL_POST_URL], context?.previousPosts)
        },
        onSettled: () => {
            client.invalidateQueries({ queryKey: [PORTAL_POST_URL] })
        },
    })
}

export const usePortalPosts = () => {
    const client = useQueryClient()

    const query = useQuery({
        queryKey: [PORTAL_POST_URL],
        queryFn: portalApi.fetchPosts,
    })

    const createPost = useCreatePortalPost()

    const deletePost = useMutation({
        mutationFn: portalApi.deletePost,
        onMutate: async (postId) => {
            await client.cancelQueries({ queryKey: [PORTAL_POST_URL] })
            const previousPosts =
                client.getQueryData<Array<Post>>([PORTAL_POST_URL]) ?? []
            const newPosts = previousPosts.filter((post) => post.id !== postId)
            client.setQueryData([PORTAL_POST_URL], newPosts)

            return { previousPosts, newPosts }
        },
        onError: (_, __, context) => {
            client.setQueryData([PORTAL_POST_URL], context?.previousPosts)
        },
        onSettled: () => {
            client.invalidateQueries({ queryKey: [PORTAL_POST_URL] })
        },
    })

    const updatePost = useUpdatePortalPost()

    return { ...query, createPost, deletePost, updatePost }
}

export const useSavePortalSchedule = () => {
    const client = useQueryClient()

    return useMutation({
        mutationFn: portalApi.saveSchedule,
        onSettled: () => {
            client.invalidateQueries({ queryKey: [PORTAL_SCHEDULE_URL] })
        },
    })
}

export const usePortalSchedule = () => {
    const savePortalSchedule = useSavePortalSchedule()

    const query = useQuery({
        queryKey: [PORTAL_SCHEDULE_URL],
        queryFn: portalApi.getSchedule,
    })

    return { ...query, savePortalSchedule }
}
