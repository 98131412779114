import { WidgetProps } from '@rjsf/utils'

import styles from './Textarea.module.scss'

function Textarea(props: WidgetProps) {
    const { value, onChange } = props

    return (
        <textarea
            className={styles.textarea}
            value={value}
            onChange={(e) => onChange(e.currentTarget.value)}
        />
    )
}

export default Textarea
