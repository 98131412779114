import { MediaType } from './types/enums/mediatype.enum'
import { PackType } from './types/enums/packtype.enum'
import { TabType } from './types/enums/tabtype.enum'

export const mapDimensions = { w: 1676, h: 1126 }
export const mapPricetagSize = 100 // px
export const mapSignPosition = 0.05 // y percentage from top
export const mapMobileCutoff = 0.1 // percent hidden from map top on mobile

export const maxImageFileSize = 1 * 1024 * 1024 // requires confirm to exceed
export const maxVideoFileSize = 40 * 1024 * 1024 // requires confirm to exceed

export const packScreenshotDimensions = { w: 2208, h: 1242 }
export const packThumbnailDimensions = [
    { w: 612, h: 612 },
    { w: 526, h: 700 },
    { w: 526, h: 794 },
]
export const packWideThumbnailDimensions = [
    { w: 930, h: 523 },
    { w: 994, h: 666 },
    { w: 912, h: 620 },
    { w: 828, h: 574 },
]
export const packScrollingScreenshotDimensions = { h: 1242 }
export const packTabThumbnailDimensions = { w: 564, h: 564 }
export const tabBackgroundDimensions = { w: 4098, h: 1614 }
export const tabIconDimensions = { w: 54, h: 54 }
export const iapImageDimensions = { w: 1024, h: 1024 }
export const iaeCardDimensions = { minW: 1920, maxW: 3840, ratio: 9 / 16 }
export const iaeDetailsPageDimensions = {
    minW: 1080,
    maxW: 2160,
    ratio: 16 / 9,
}
export const bannerMoonBgDimensions = { ratio: 1, w: 640, h: 640 }

export const maxDisplayNameCharacterCount = 30 // validation will warn if any text is over
export const maxDescriptionCharacterCount = 600 // validation will warn if any text is over

/**
 * Instead of using types as strings in code directly, this is the only place that we keep he strings and import these object wherever needed.
 * More maintainable! :)
 */

export const itemPropertiesByPackType = {
    [PackType.PLAYSET]: ['locations', 'characters'],
    [PackType.HOME_DESIGNER]: ['templates', 'furniture', 'surfaceDesigns'],
    [PackType.CHARACTER_CREATOR]: ['outfits', 'accessories', 'hairStyles'],
}
export const packTypeToDisplayText = {
    [PackType.MULTIPACK]: 'Bundle',
    [PackType.PLAYSET]: 'Playset',
    [PackType.HOME_DESIGNER]: 'Home Designer',
    [PackType.OUTFIT_MAKER]: 'Outfit Maker',
    [PackType.CHARACTER_CREATOR]: 'Character Creator',
}

export const mediaTypeToDisplayText = {
    [MediaType.SCROLLING_SCREENSHOT]: 'Scrolling screenshot',
    [MediaType.SCREENSHOT]: 'Screenshot',
    [MediaType.THUMBNAIL]: 'Thumbnail square',
    [MediaType.WIDE_THUMBNAIL]: 'Thumbnail for bundle',
    [MediaType.VIDEO]: 'Video',
    [MediaType.TAB_THUMBNAIL]: 'Tab thumbnail',
    [MediaType.IAP_IMAGE]: 'In-app purchase image',
    [MediaType.MAP_LOCATION]: 'Location',
    [MediaType.MAP_BACKGROUND]: 'Background',
    [MediaType.TAB_ICON]: 'Icon',
    [MediaType.TAB_BACKGROUND]: 'Background',
}

export const packPropertyToDisplayText = {
    locations: 'Locations',
    characters: 'Characters',
    templates: 'Templates',
    furniture: 'Furniture',
    surfaceDesigns: 'Surface Designs',
    outfits: 'Outfits',
    accessories: 'Accessories',
    hairStyles: 'Hair Styles',
}

export const shopTabTypeLabels = {
    [TabType.WORLD_MAP]: 'World map',
    [TabType.PACKS_LIST]: 'Packs list',
}
