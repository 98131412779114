import { PropsWithChildren } from 'react'
import classNames from 'classnames'

import styles from './Chip.module.scss'

export interface ChipProps extends PropsWithChildren {
    color?: 'default' | 'primary' | 'secondary' | 'positive' | 'danger'
}

export const Chip = ({ color, children }: ChipProps) => {
    return (
        <span
            className={classNames(styles.container, styles[color ?? 'default'])}
        >
            {children}
        </span>
    )
}
