import cn from 'classnames'

import { BannerStatus } from '../../types/enums/bannerType.enum.ts'

import styles from './StatusLabel.module.scss'

type StatusLabelProps = {
    status: BannerStatus
}

const StatusToClassMap = {
    [BannerStatus.ACTIVE]: styles.active,
    [BannerStatus.INACTIVE]: styles.inactive,
    [BannerStatus.DEFAULT]: styles.default,
}

export const StatusLabel = (props: StatusLabelProps) => {
    return (
        <div className={cn(styles.statusLabel, StatusToClassMap[props.status])}>
            {props.status}
        </div>
    )
}
