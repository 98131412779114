import { FormEventHandler, useId, useMemo, useState } from 'react'

import { Button } from '../../shared/Button'
import ModalV2 from '../../shared/ModalV2'
import { TableColumn } from '../../shared/Table/TableColumn'
import { createSortFn, SortDescriptor } from '../../shared/Table/TableUtils'

import { Post, usePortalPosts } from '../../hooks/portal'

import { assert } from '../../utils/assert'

import styles from './SelectPostModal.module.scss'

export interface SelectPostModalProps {
    onPostsSelected?: (
        post: Array<Post>,
        startTime: Date,
        endTime: Date | null
    ) => void
    onClose?: () => void
}

export const SelectPostModal = ({
    onPostsSelected,
    onClose,
}: SelectPostModalProps) => {
    const { data: posts } = usePortalPosts()

    const [selectedPosts, setSelectedPosts] = useState<Array<Post>>([])
    const [searchValue, setSearchValue] = useState('')
    const [sortDescriptor, setSortDescriptor] =
        useState<SortDescriptor<Post> | null>(null)

    const startInputId = useId()
    const endInputId = useId()

    const filteredPosts = useMemo(() => {
        let filteredPosts = posts
        const normalizedSearchValue = searchValue.toLowerCase()
        if (searchValue) {
            filteredPosts = filteredPosts?.filter(
                (post) =>
                    post.templateId
                        .toLowerCase()
                        .includes(normalizedSearchValue) ||
                    post.id.toLowerCase().includes(normalizedSearchValue)
            )
        }
        if (sortDescriptor) {
            filteredPosts = filteredPosts?.toSorted(
                createSortFn(sortDescriptor)
            )
        }

        return filteredPosts
    }, [searchValue, selectedPosts, sortDescriptor, posts])

    const togglePost = (post: Post) => {
        setSelectedPosts((selectedPosts) => {
            const isSelected = selectedPosts.some((p) => p.id === post.id)

            if (isSelected) {
                return selectedPosts.filter((p) => p.id !== post.id)
            } else {
                return [...selectedPosts, post]
            }
        })
    }

    const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()

        const formData = new FormData(e.currentTarget)
        const startTimeString = formData.get('startTime')?.toString()
        const endTimeString = formData.get('endTime')?.toString()
        assert(startTimeString, 'startTime is required')

        const startTime = new Date(startTimeString)
        const endTime = endTimeString ? new Date(endTimeString) : null

        onPostsSelected?.(selectedPosts, startTime, endTime)
    }

    return (
        <ModalV2 className={styles.modal} onClose={onClose}>
            <header className={styles.header}>
                <h1>Add post to publication</h1>
                <input
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search"
                    className={styles.searchBar}
                    role="search"
                />

                <Button color="secondary" onClick={onClose}>
                    X
                </Button>
            </header>

            <table className={styles.table}>
                <thead>
                    <tr>
                        <TableColumn>{/* Actions */}</TableColumn>
                        <TableColumn
                            allowSorting
                            activeSortDescriptor={sortDescriptor}
                            onSortChange={setSortDescriptor}
                            column="id"
                        >
                            Name
                        </TableColumn>
                        <TableColumn
                            allowSorting
                            activeSortDescriptor={sortDescriptor}
                            onSortChange={setSortDescriptor}
                            column="templateId"
                        >
                            Type
                        </TableColumn>
                        <TableColumn>Size</TableColumn>
                    </tr>
                </thead>
                <tbody>
                    {!filteredPosts
                        ? null
                        : filteredPosts.map((post) => (
                              <tr
                                  key={post.id}
                                  onClick={() => {
                                      togglePost(post)
                                  }}
                              >
                                  <td className={styles.checkbox}>
                                      <input
                                          type="checkbox"
                                          checked={selectedPosts.some(
                                              (p) => p.id === post.id
                                          )}
                                          onChange={() => {
                                              togglePost(post)
                                          }}
                                          onClick={(e) => {
                                              e.stopPropagation()
                                          }}
                                      />
                                  </td>
                                  <td>{post.id}</td>
                                  <td>{post.templateId}</td>
                                  <td>??</td>
                              </tr>
                          ))}
                </tbody>
            </table>
            <footer className={styles.footer}>
                <form className={styles.form} onSubmit={onSubmit}>
                    <label htmlFor={startInputId}>Start</label>
                    <input
                        id={startInputId}
                        name="startTime"
                        type="datetime-local"
                        className={styles.dateInput}
                        required
                    />
                    <label htmlFor={endInputId}>To</label>
                    <input
                        id={endInputId}
                        name="endTime"
                        type="datetime-local"
                        className={styles.dateInput}
                    />
                    <Button
                        disabled={selectedPosts.length === 0}
                        className={styles.addButton}
                        type="submit"
                    >
                        Add Posts ({selectedPosts.length})
                    </Button>
                </form>
            </footer>
        </ModalV2>
    )
}
