import { ReactNode } from 'react'
import classNames from 'classnames'

import styles from './ModalV2.module.scss'

type ModalV2Props = {
    children: ReactNode
    className?: string
    onClose?: () => void
}

export function ModalV2(props: ModalV2Props) {
    const { children, className, onClose } = props
    return (
        <div
            className={'modalBackdrop'}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    onClose?.()
                }
            }}
        >
            <div
                className={classNames(styles.modal, className)}
                data-testid="modal"
            >
                {children}
            </div>
        </div>
    )
}
