import { forwardRef, PropsWithChildren, ReactElement, Ref } from 'react'
import classNames from 'classnames'

import { SortDescriptor, toggleSortDescriptor } from './TableUtils'

import { SortIcon } from '..'

export interface SortedTableColumnProps<Item> {
    column: keyof Item
    allowSorting: true
    activeSortDescriptor: SortDescriptor<Item> | null
    onSortChange?: (descriptor: SortDescriptor<Item>) => void
}
export interface NonSortedTableColumnProps {
    column?: never
    allowSorting?: false
    activeSortDescriptor?: never
}

export type TableColumnProps<Item> = PropsWithChildren &
    (SortedTableColumnProps<Item> | NonSortedTableColumnProps)

export const TableColumn = forwardRef<
    HTMLTableCellElement,
    TableColumnProps<unknown>
>(function TableColumn(props, ref) {
    return (
        <th ref={ref}>
            <div
                className={classNames({
                    ['sortable']: props.allowSorting,
                })}
                onClick={() => {
                    if (props.allowSorting) {
                        props.onSortChange?.(
                            toggleSortDescriptor(
                                props.activeSortDescriptor,
                                props.column
                            )
                        )
                    }
                }}
            >
                {props.children}
                {props.allowSorting && (
                    <SortIcon
                        isActive={
                            props.activeSortDescriptor?.column === props.column
                        }
                        isSortAscend={
                            props.activeSortDescriptor?.direction ===
                            'ascending'
                        }
                    />
                )}
            </div>
        </th>
    )
}) as <Item>(
    props: TableColumnProps<Item> & { ref?: Ref<HTMLTableCellElement> }
) => ReactElement
