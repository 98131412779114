import File from './File/File'
import Select from './Select/Select'
import Textarea from './Textarea/Textarea'

import { RegistryWidgetsType } from '@rjsf/utils'

const widgets: RegistryWidgetsType = {
    TextareaWidget: Textarea,
    FileWidget: File,
    SelectWidget: Select,
}

export default widgets
