import { Publication } from '../hooks/portal'

export type PublicationStatus = 'scheduled' | 'published' | 'expired'

export const getPublicationStatus = (
    publication: Pick<Publication, 'startTime' | 'endTime'>
): PublicationStatus => {
    const now = Date.now()
    const start = new Date(publication.startTime).getTime()
    const end = publication.endTime
        ? new Date(publication.endTime).getTime()
        : Number.MAX_SAFE_INTEGER

    if (now < start && now < end) {
        return 'scheduled'
    } else if (now > start && now < end) {
        return 'published'
    } else {
        return 'expired'
    }
}
