import Selector from '../../../shared/Selector'

import { WidgetProps } from '@rjsf/utils'

function Select(props: WidgetProps) {
    const { options } = props
    return (
        <Selector>
            {options?.enumOptions?.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Selector>
    )
}

export default Select
