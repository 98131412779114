import { useSelectedLanguage } from '../hooks/selectedLanugage'

import { LanguageType } from '../types/enums/languagetype.enum'

export default function LanguageSelector() {
    const { selectedLanguage, setSelectedLanguage } = useSelectedLanguage()

    return (
        <select
            className="blurrable"
            value={selectedLanguage}
            onChange={(e) => {
                setSelectedLanguage(e.target.value as LanguageType)
            }}
        >
            {Object.keys(LanguageType).map((item, i) => {
                return (
                    <option
                        key={'lang' + i}
                        value={LanguageType[item as keyof typeof LanguageType]}
                    >
                        {item}
                    </option>
                )
            })}
        </select>
    )
}
