import { useState } from 'react'
import axios, { AxiosError } from 'axios'

import { handleError } from '../utils/utils.ts'

import { Banner, CreateOrUpdateBanner } from '../types/bannerTypes'

type BasepathOption = {
    basepath?: string
}

export function useBanners(options: BasepathOption = {}) {
    const { basepath } = options
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)
    const [banners, setBanners] = useState<Banner[]>([])

    async function fetch() {
        const url = basepath ? `${basepath}/v2/banners` : `/api/v2/banners`

        setError(null)
        setIsLoading(true)

        try {
            const { data } = await axios.get<Banner[]>(url)

            setBanners(data)
            setIsLoading(false)
        } catch (error: unknown) {
            setError(error as AxiosError)
            setIsLoading(false)
        }
    }

    return {
        banners,
        isLoading,
        error: error
            ? handleError('Fetch banners error', error, false)
            : undefined,
        fetch,
    }
}

export function useBanner(bannerId: string, options: BasepathOption = {}) {
    const { basepath } = options
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)
    const [banner, setBanner] = useState<Banner | null>(null)

    async function fetch() {
        const url = basepath
            ? `${basepath}/v2/banners/${bannerId}`
            : `/api/v2/banners/${bannerId}`

        setError(null)
        setIsLoading(true)

        try {
            const { data } = await axios.get<Banner>(url)

            setBanner(data)
            setIsLoading(false)
        } catch (error: unknown) {
            setError(error as AxiosError)
            setIsLoading(false)
        }
    }

    return {
        banner,
        isLoading,
        error: error
            ? handleError('Fetch banner error', error, false)
            : undefined,
        fetch,
    }
}

export function useCreateBanner(options: BasepathOption = {}) {
    const { basepath } = options
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)
    const [id, setId] = useState<number | null>(null)

    async function createBanner(body: CreateOrUpdateBanner) {
        const url = basepath ? `${basepath}/v2/banners` : `/api/v2/banners`

        setError(null)
        setIsLoading(true)

        try {
            const { data } = await axios.post<{ id: number }>(url, body)

            setId(data.id)
            setIsLoading(false)
        } catch (error: unknown) {
            setError(error as AxiosError)
            setIsLoading(false)
        }
    }

    return {
        bannerId: id,
        isLoading,
        error: error
            ? handleError('Create banner error', error, false)
            : undefined,
        createBanner,
    }
}

export function useUpdateBanner(
    bannerId: string,
    options: BasepathOption = {}
) {
    const { basepath } = options
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)

    async function updateBanner(body: CreateOrUpdateBanner) {
        const url = basepath
            ? `${basepath}/v2/banners/${bannerId}`
            : `/api/v2/banners/${bannerId}`

        setError(null)
        setIsLoading(true)

        try {
            await axios.put<void>(url, body)

            setIsLoading(false)
        } catch (error: unknown) {
            setError(error as AxiosError)
            setIsLoading(false)
        }
    }

    return {
        isLoading,
        error: error
            ? handleError('Update banner error', error, false)
            : undefined,
        updateBanner,
    }
}
