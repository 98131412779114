import Joi, { ValidationResult } from 'joi'
import _ from 'lodash'

import { LanguageType } from '../types/enums/languagetype.enum.ts'

import { Banner, CreateOrUpdateBanner } from '../types/bannerTypes'

const languages = Object.values(LanguageType)

export function getLocalizedFieldSchema(
    languages: LanguageType[],
    callback: (lang: LanguageType) => Joi.StringSchema
) {
    const schema: Partial<Record<LanguageType, Joi.StringSchema>> = {}

    languages.forEach((lang) => {
        schema[lang] = callback(lang)
    })

    return schema
}

type BannerValidationItem = CreateOrUpdateBanner & {
    activeToDateCopy: Banner['activeToDate']
}

const createSchemas = () => {
    const bannerErrorSchema = Joi.object()
        .options({ abortEarly: false })
        .keys({
            name: Joi.string().required(),
            title: Joi.object()
                .keys(
                    getLocalizedFieldSchema(languages, (lang) => {
                        const charLimit = 30
                        const schema =
                            lang === LanguageType.English
                                ? Joi.string().required()
                                : Joi.string().optional()

                        return schema.max(charLimit).messages({
                            'string.base': `"${lang}" for title must be a type of string`,
                            'string.empty': `"${lang}" for title is missing`,
                            'string.max': `"${lang}" for title shouldn't exceed ${charLimit} characters`,
                            'any.required': `"${lang}" for title is missing`,
                        })
                    })
                )
                .required(),
            subtitle: Joi.alternatives(
                Joi.object()
                    .keys(
                        getLocalizedFieldSchema(languages, (lang) => {
                            const charLimit = 30

                            return Joi.string()
                                .optional()
                                .max(charLimit)
                                .messages({
                                    'string.base': `"${lang}" for subtitle must be a type of string`,
                                    'string.empty': `"${lang}" for subtitle is missing`,
                                    'string.max': `"${lang}" for subtitle shouldn't exceed ${charLimit} characters`,
                                    'any.required': `"${lang}" for subtitle is missing`,
                                })
                        })
                    )
                    .required(),
                null
            ),
            backgroundMediaId: Joi.number().integer().positive().messages({
                'number.base': `Background picture is missing`,
            }),
            type: Joi.valid('moon').required(),
            actionType: Joi.valid('open_pdp', 'open_shop').required(),
            actionParameters: Joi.object({
                packId: Joi.string().optional(),
            })
                .required()
                .allow(null),
            positionScene: Joi.valid('start_screen').required(),
            positionPlacement: Joi.valid('top_left').required(),
            activeToDateCopy: Joi.any(),
            activeFromDate: Joi.date()
                .required()
                .less(Joi.ref('activeToDateCopy'))
                .messages({
                    'date.base': '"Starts on" date must be selected',
                    'date.less':
                        '"Starts on" date must be before "Ends on" date',
                }),
            activeToDate: Joi.date()
                .required()
                .greater(Joi.ref('activeFromDate'))
                .messages({
                    'date.base': '"Ends on" date must be selected',
                    'date.greater':
                        '"Ends on" date must be after "Starts on" date',
                }),
            isDefault: Joi.boolean().optional(),
        })

    const bannerWarningSchema = bannerErrorSchema.keys({
        title: Joi.object()
            .keys(
                getLocalizedFieldSchema(languages, (lang) => {
                    return Joi.string()
                        .required()
                        .messages({
                            'string.base': `"${lang}" for title must be a type of string`,
                            'string.empty': `"${lang}" for title is missing`,
                            'any.required': `"${lang}" for title is missing`,
                        })
                })
            )
            .required(),
        subtitle: Joi.object()
            .keys(
                getLocalizedFieldSchema(languages, (lang) => {
                    return Joi.string()
                        .required()
                        .messages({
                            'string.base': `"${lang}" for subtitle must be a type of string`,
                            'string.empty': `"${lang}" for subtitle is missing`,
                            'any.required': `"${lang}" for subtitle is missing`,
                        })
                })
            )
            .required()
            .allow(null),
    })

    return {
        bannerWarningSchema,
        bannerErrorSchema,
    }
}

function validate(item: CreateOrUpdateBanner): {
    errors: ValidationResult
    warnings: ValidationResult
} {
    const validationItem: BannerValidationItem = Object.assign(
        _.cloneDeep(item),
        {
            activeToDateCopy: item.activeToDate,
        }
    )
    const { bannerErrorSchema, bannerWarningSchema } = createSchemas()
    const warnings = bannerWarningSchema.validate(validationItem)
    const errors = bannerErrorSchema.validate(validationItem)
    return { errors, warnings }
}

export default validate
