import { ChangeEvent } from 'react'

import { LabelInput } from '../../shared'

import { Banner } from '../../types/bannerTypes'

export enum PossibleBannerActionParameters {
    PackId = 'packId',
}

type BannerActionProps = {
    parameters: Banner['actionParameters']
    onInputChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const BannerAction = (props: BannerActionProps) => {
    const { parameters, onInputChange } = props

    return (
        <div>
            <LabelInput
                size="semiwide"
                name={PossibleBannerActionParameters.PackId}
                value={parameters?.packId || ''}
                tooltip="Tapping the banner will take the user to this pack in the shop "
                label="Pack ID (optional)"
                onChange={onInputChange}
            />
        </div>
    )
}
