import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    OnDragEndResponder,
} from '@hello-pangea/dnd'
import classNames from 'classnames'
import { ValidationResult } from 'joi'

import { SortIcon, Validator } from '../shared/index.js'

import { useComputedSizes } from '../hooks/utils.ts'

import { formatDate } from '../utils/dates.ts'
import { getPackThumbnail, isProductInactive } from '../utils/utils.ts'
import { SortByType } from './utils.tsx'

import { RouteTo } from '../routes.ts'

import { Pack } from '../types/packTypes'

import thumbPlaceholderImg from '../assets/thumb_placeholder.png'

type PacksTableProps = {
    packs: Pack[]
    isSortAscend: boolean
    sortBy: SortByType | undefined
    isDragDropEnabled: boolean
    onClickCopy: (packId: string) => void
    onDragEnd: OnDragEndResponder
    onClickSort: (sortBy: SortByType) => void
    validationResults: {
        [packId: string]: {
            errors: ValidationResult
            warnings: ValidationResult
        }
    }
}

function PacksTable(props: PacksTableProps) {
    const {
        packs,
        isSortAscend,
        sortBy,
        isDragDropEnabled,
        onClickCopy,
        onDragEnd,
        onClickSort,
        validationResults,
    } = props

    const navigate = useNavigate()

    const refs = {
        thumb: useRef<HTMLTableCellElement>(null),
        name: useRef<HTMLTableCellElement>(null),
        id: useRef<HTMLTableCellElement>(null),
        start: useRef<HTMLTableCellElement>(null),
        end: useRef<HTMLTableCellElement>(null),
        valid: useRef<HTMLTableCellElement>(null),
        copy: useRef<HTMLTableCellElement>(null),
        dragHandle: useRef<HTMLTableCellElement>(null),
        row: useRef<HTMLTableRowElement | null>(null),
    }
    const { computedSizes, getComputedSizes } = useComputedSizes(refs)

    const getTdStyle = (isDragging: boolean, name: keyof typeof refs) => {
        return isDragging
            ? {
                  ...computedSizes.current[name],
                  backgroundColor: '#EFF8FB',
              }
            : {}
    }

    const getDraggableRow = (
        provided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
        pack: Pack,
        isDragDropEnabled: boolean
    ) => {
        const thumbnail = getPackThumbnail(pack)

        return (
            <tr
                key={pack.packId}
                onClick={() => navigate(RouteTo.PackEdit(pack.packId))}
                ref={(el) => {
                    refs.row.current = el
                    provided.innerRef(el)
                }}
                style={{ ...computedSizes.current.row }}
                {...provided.draggableProps}
            >
                <td
                    ref={refs.thumb}
                    style={getTdStyle(snapshot.isDragging, 'thumb')}
                >
                    <img
                        alt={`thumbnail for ${pack.name}`}
                        className="thumb"
                        src={
                            thumbnail
                                ? thumbnail.url.replace('{0}', '@1x')
                                : thumbPlaceholderImg
                        }
                    />
                </td>
                <td
                    className="alignLeft"
                    ref={refs.name}
                    style={getTdStyle(snapshot.isDragging, 'name')}
                >
                    {pack.name}
                </td>
                <td
                    className={classNames('alignLeft')}
                    ref={refs.id}
                    style={getTdStyle(snapshot.isDragging, 'id')}
                >
                    {pack.packId}
                </td>
                <td
                    className={classNames(
                        'alignRight',
                        isProductInactive(pack) && 'inactive'
                    )}
                    ref={refs.start}
                    style={getTdStyle(snapshot.isDragging, 'start')}
                >
                    {formatDate(pack.activeFromDate)}
                </td>
                <td
                    className={classNames(
                        'alignRight',
                        isProductInactive(pack) && 'inactive'
                    )}
                    ref={refs.end}
                    style={getTdStyle(snapshot.isDragging, 'end')}
                >
                    {formatDate(pack.activeToDate)}
                </td>
                <td
                    className="alignRight validate"
                    ref={refs.valid}
                    style={getTdStyle(snapshot.isDragging, 'valid')}
                >
                    <Validator
                        id={pack.packId}
                        height={23}
                        validationResults={validationResults[pack.packId]}
                        item={pack}
                    />
                </td>
                <td
                    className="alignRight"
                    ref={refs.copy}
                    style={getTdStyle(snapshot.isDragging, 'copy')}
                >
                    <button
                        className="small"
                        onClick={(e) => {
                            e.stopPropagation()
                            onClickCopy(pack.packId)
                        }}
                    >
                        Copy to...
                    </button>
                </td>
                {isDragDropEnabled && (
                    <td
                        ref={refs.dragHandle}
                        style={getTdStyle(snapshot.isDragging, 'dragHandle')}
                        className="alignRight dragHandle"
                        {...provided.dragHandleProps}
                    >
                        ≡
                    </td>
                )}
            </tr>
        )
    }

    return (
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th />
                    <th>
                        <div
                            className="sortable"
                            onClick={() => onClickSort('name')}
                        >
                            Name
                            <SortIcon
                                isActive={sortBy === 'name'}
                                isSortAscend={isSortAscend}
                            />
                        </div>
                    </th>
                    <th>
                        <div
                            className="sortable"
                            onClick={() => onClickSort('packId')}
                        >
                            ID
                            <SortIcon
                                isActive={sortBy === 'packId'}
                                isSortAscend={isSortAscend}
                            />
                        </div>
                    </th>
                    <th>
                        <div
                            className="sortable"
                            style={{ justifyContent: 'flex-end' }}
                            onClick={() => onClickSort('activeFromDate')}
                        >
                            Start
                            <SortIcon
                                isActive={sortBy === 'activeFromDate'}
                                isSortAscend={isSortAscend}
                            />
                        </div>
                    </th>
                    <th>
                        <div
                            className="sortable"
                            style={{ justifyContent: 'flex-end' }}
                            onClick={() => onClickSort('activeToDate')}
                        >
                            End
                            <SortIcon
                                isActive={sortBy === 'activeToDate'}
                                isSortAscend={isSortAscend}
                            />
                        </div>
                    </th>
                    <th />
                    <th />
                    {isDragDropEnabled && <th />}
                </tr>
            </thead>
            <DragDropContext
                onDragEnd={onDragEnd}
                onBeforeDragStart={getComputedSizes}
            >
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <tbody ref={provided.innerRef}>
                            {packs.map((item, index) => (
                                <Draggable
                                    key={item.packId}
                                    draggableId={item.packId}
                                    index={index}
                                    isDragDisabled={!isDragDropEnabled}
                                >
                                    {(dragProvided, dragSnapshot) =>
                                        getDraggableRow(
                                            dragProvided,
                                            dragSnapshot,
                                            item,
                                            isDragDropEnabled
                                        )
                                    }
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </tbody>
                    )}
                </Droppable>
            </DragDropContext>
        </table>
    )
}

export default PacksTable
