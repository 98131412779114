import { Link, useParams } from 'react-router-dom'

import { ToggleButton, ToggleButtonGroup } from '../shared/ToggleButton'
import { PortalPostLibrary } from './PortalPostLibrary'
import { PortalPublicationFeed } from './publication/PortalPublicationFeed'
import { Section } from '../shared'

import { RouteTo } from '../routes'

import styles from './Portal.module.scss'

export const Portal = () => {
    const params = useParams()
    const view = params['view'] ?? 'library'

    return (
        <Section className={styles.container}>
            <menu className={styles.navigation}>
                <ToggleButtonGroup value={view} color="primary">
                    <Link to={RouteTo.Portal('library')}>
                        <ToggleButton value="library">
                            Post Library
                        </ToggleButton>
                    </Link>

                    <Link to={RouteTo.Portal('publication')}>
                        <ToggleButton value="publication">
                            Publication Feed
                        </ToggleButton>
                    </Link>
                </ToggleButtonGroup>
            </menu>
            {view === 'library' ? (
                <PortalPostLibrary />
            ) : view === 'publication' ? (
                <PortalPublicationFeed />
            ) : null}
        </Section>
    )
}
