import { filterActive } from '../utils/utils.ts'

import { BannerStatus } from '../types/enums/bannerType.enum.ts'

import { Banner } from '../types/bannerTypes'

export function filterBannersBySearchString(
    banners: Banner[],
    searchString: string
) {
    if (!searchString) {
        return banners
    }

    const loweredSearchString = searchString.toLowerCase()
    return banners.filter((banner) =>
        banner.name?.toLowerCase()?.includes(loweredSearchString)
    )
}

export function filterBannersByStatus(
    banners: Banner[],
    filterByStatus: string
) {
    if (filterByStatus === 'all') {
        return banners
    }

    if (filterByStatus === BannerStatus.DEFAULT) {
        return banners.filter((banner) => banner.isDefault)
    }

    return filterActive(
        banners.filter((banner) => !banner.isDefault),
        filterByStatus === BannerStatus.ACTIVE,
        'activeFromDate',
        'activeToDate'
    )
}
