import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react'
import classNames from 'classnames'

import { Spinner } from './Spinner'

import styles from './Button.module.scss'

type HtmlButtonProps = DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>

export type ButtonProps = {
    color?: 'default' | 'primary' | 'secondary' | 'positive' | 'danger'
    size?: 'large' | 'small' | 'medium'
    loading?: boolean
    startContent?: ReactNode
} & HtmlButtonProps

export const Button = (props: ButtonProps) => {
    const {
        color,
        size,
        loading: isLoading,
        disabled: isDisabled,
        startContent,
        children,
        className,
        ...buttonProps
    } = props
    return (
        <button
            disabled={isLoading || isDisabled}
            {...buttonProps}
            className={classNames(styles.container, color, size, className)}
        >
            {isLoading && <Spinner />}
            {startContent}
            {children}
        </button>
    )
}
