import { matchPath } from 'react-router-dom'

export enum RoutePath {
    Root = '/',
    Packs = '/packs/',
    PackEdit = '/pack/:id',
    PackNew = '/newpack',
    Portal = '/portal/:view?',
    PortalPostEdit = '/portal/post/:id',
    PortalPostNew = '/portal/post/new',
    Login = '/login',
    Gifts = '/gifts/',
    GiftEdit = '/gift/:id',
    GiftNew = '/newgift',
    Maps = '/maps/',
    MapEdit = '/map/:id',
    Carousels = '/carousels/',
    CarouselEdit = '/carousel/:id',
    CarouselNew = '/newcarousel',
    Tabs = '/shoptabs/',
    TabEdit = '/shoptab/:id',
    TabNew = '/newshoptab',
    Accounts = '/accounts/',
    GroupedProfiles = '/grouped-profiles',
    Versions = '/versions/',
    Medias = '/medias',
    PriceAutomationApple = '/price-automation-apple/',
    PriceAutomationGoogle = '/price-automation-google/',
    ExportSKUs = '/export-skus',
    Tools = '/tools',
    InAppEvents = '/iaes/',
    InAppEventsEdit = '/iae/:id',
    InAppEventsNew = '/newiae',
    Banners = '/banners',
    BannerEdit = '/banners/:id',
    BannerNew = '/newbanner',
}

export const RouteTo = {
    Root: () => RoutePath.Root,
    Portal: (view: 'library' | 'publication' = 'library') =>
        RoutePath.Portal.replace(':view?', view),
    PortalPostEdit: (id: string) => RoutePath.PortalPostEdit.replace(':id', id),
    PortalPostNew: () => RoutePath.PortalPostNew,
    Packs: () => RoutePath.Packs,
    PackEdit: (id: string) => RoutePath.PackEdit.replace(':id', id),
    PackNew: () => RoutePath.PackNew,
    Login: (redirectTo?: string) => {
        return redirectTo
            ? `${RoutePath.Login}?redirectTo=${redirectTo}`
            : RoutePath.Login
    },
    Gifts: () => RoutePath.Gifts,
    GiftEdit: (id: string) => RoutePath.GiftEdit.replace(':id', id),
    GiftNew: () => RoutePath.GiftNew,
    Maps: () => RoutePath.Maps,
    MapEdit: (id: string) => RoutePath.MapEdit.replace(':id', id),
    Carousels: () => RoutePath.Carousels,
    CarouselEdit: (id: string) => RoutePath.CarouselEdit.replace(':id', id),
    CarouselNew: () => RoutePath.CarouselNew,
    Tabs: () => RoutePath.Tabs,
    TabEdit: (id: string) => RoutePath.TabEdit.replace(':id', id),
    TabNew: () => RoutePath.TabNew,
    Accounts: () => RoutePath.Accounts,
    GroupedProfiles: () => RoutePath.GroupedProfiles,
    Versions: () => RoutePath.Versions,
    Medias: () => RoutePath.Medias,
    Banners: () => RoutePath.Banners,
    BannerEdit: (id: string) => RoutePath.BannerEdit.replace(':id', id),
    BannerNew: () => RoutePath.BannerNew,
    PriceAutomationApple: () => RoutePath.PriceAutomationApple,
    PriceAutomationGoogle: () => RoutePath.PriceAutomationGoogle,
    ExportSKUs: () => RoutePath.ExportSKUs,
    Tools: () => RoutePath.Tools,
    InAppEvents: () => RoutePath.InAppEvents,
    InAppEventsEdit: (id: string) =>
        RoutePath.InAppEventsEdit.replace(':id', id),
    InAppEventsNew: () => RoutePath.InAppEventsNew,
}

export function getRouteByUrl(url: string): keyof typeof RoutePath | undefined {
    for (const [name, path] of Object.entries(RoutePath)) {
        if (matchPath(path, url) !== null) {
            return name as keyof typeof RoutePath
        }
    }

    return undefined
}
