export interface SortDescriptor<Item> {
    column: keyof Item
    direction: 'ascending' | 'descending'
}

export const toggleSortDescriptor = <Item>(
    previousDescriptor: SortDescriptor<Item> | null,
    column: keyof Item
): SortDescriptor<Item> => {
    return {
        column,
        direction:
            column === previousDescriptor?.column &&
            previousDescriptor?.direction === 'descending'
                ? 'ascending'
                : 'descending',
    }
}

export const createSortFn = <Item>(sortDescriptor: SortDescriptor<Item>) => {
    return (a: Item, b: Item) => {
        const first = a[sortDescriptor.column]
        const second = b[sortDescriptor.column]

        let cmp = (Number(first) || first) < (Number(second) || second) ? -1 : 1

        if (sortDescriptor.direction === 'descending') {
            cmp *= -1
        }

        return cmp
    }
}
