import { MouseEvent, useRef } from 'react'
import { Tooltip } from 'react-tooltip'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    OnDragEndResponder,
} from '@hello-pangea/dnd'
import cn from 'classnames'

import { StatusLabel } from '../StatusLabel/StatusLabel.tsx'

import { useComputedSizes } from '../../hooks/utils.ts'

import { formatDate } from '../../utils/dates.ts'
import { isProductInactive } from '../../utils/utils.ts'

import { BannerStatus } from '../../types/enums/bannerType.enum.ts'

import { Banner } from '../../types/bannerTypes'

import styles from './BannersTable.module.scss'

type BannersTableProps = {
    banners: Banner[]
    onBannerClick: (id: Banner['id']) => void
    onDragEnd: OnDragEndResponder
    onCopyBannerClick: (
        e: MouseEvent<HTMLButtonElement>,
        banner: Banner
    ) => void
    onDeleteBannerClick: (
        e: MouseEvent<HTMLButtonElement>,
        id: Banner['id']
    ) => void
    isDragDropDisabled: boolean
}

const BannersTable = function (props: BannersTableProps) {
    const { banners, isDragDropDisabled } = props

    const refs = {
        name: useRef<HTMLTableCellElement>(null),
        status: useRef<HTMLTableCellElement>(null),
        linkId: useRef<HTMLTableCellElement>(null),
        start: useRef<HTMLTableCellElement>(null),
        end: useRef<HTMLTableCellElement>(null),
        buttons: useRef<HTMLTableCellElement>(null),
        draghandle: useRef<HTMLTableCellElement>(null),
        row: useRef<HTMLTableRowElement | null>(null),
    }
    const { computedSizes, getComputedSizes } = useComputedSizes(refs)

    const getTdStyle = (isDragging: boolean, columnName: keyof typeof refs) => {
        return isDragging
            ? {
                  ...computedSizes.current[columnName],
                  backgroundColor: '#EFF8FB',
              }
            : {}
    }

    const getBannerStatus = (banner: Banner): BannerStatus => {
        if (banner.isDefault) {
            return BannerStatus.DEFAULT
        }

        if (isProductInactive(banner)) {
            return BannerStatus.INACTIVE
        }

        return BannerStatus.ACTIVE
    }

    const getDraggableRow = (
        provided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
        banner: Banner,
        isDragDropDisabled: boolean
    ) => {
        const status = getBannerStatus(banner)

        return (
            <tr
                key={'tr_banner_draggablerow_id_' + banner.id}
                onClick={() => props.onBannerClick(banner.id)}
                className="draggableBanner"
                ref={(ref) => {
                    refs.row.current = ref
                    provided.innerRef(ref)
                }}
                style={{ ...computedSizes.current.row }}
                {...provided.draggableProps}
            >
                <td
                    ref={refs.name}
                    style={getTdStyle(snapshot.isDragging, 'name')}
                >
                    <div className={styles.name}>
                        <img
                            src={banner.backgroundMedia.url.replace(
                                '{0}',
                                '@1x'
                            )}
                            alt="banner_background"
                        />
                        <span>{banner.name}</span>
                    </div>
                </td>
                <td
                    ref={refs.status}
                    style={getTdStyle(snapshot.isDragging, 'status')}
                >
                    <StatusLabel status={status} />
                </td>
                <td
                    ref={refs.linkId}
                    style={getTdStyle(snapshot.isDragging, 'linkId')}
                >
                    {banner.actionParameters?.packId ? (
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            className={styles.link}
                            href={`/pack/${banner.actionParameters.packId}`}
                        >
                            {banner.actionParameters.packId}
                        </a>
                    ) : null}
                </td>
                <td
                    ref={refs.start}
                    style={getTdStyle(snapshot.isDragging, 'start')}
                >
                    {formatDate(banner.activeFromDate)}
                </td>
                <td
                    ref={refs.end}
                    style={getTdStyle(snapshot.isDragging, 'end')}
                >
                    {formatDate(banner.activeToDate)}
                </td>
                <td
                    ref={refs.buttons}
                    className={cn(styles.alignRight, styles.buttons)}
                    style={getTdStyle(snapshot.isDragging, 'buttons')}
                >
                    <button
                        className="primary small"
                        onClick={() => props.onBannerClick(banner.id)}
                    >
                        Edit
                    </button>
                    <button
                        className="small"
                        onClick={(e) => props.onCopyBannerClick(e, banner)}
                    >
                        Copy to...
                    </button>
                    <button
                        className="danger small"
                        onClick={(e) => props.onDeleteBannerClick(e, banner.id)}
                    >
                        Delete
                    </button>
                </td>
                <td
                    ref={refs.draghandle}
                    style={getTdStyle(snapshot.isDragging, 'draghandle')}
                    className={cn(
                        styles.alignRight,
                        'dragHandle',
                        isDragDropDisabled ? styles.disabled : ''
                    )}
                    key={'ts_handle_' + banner.id}
                    {...provided.dragHandleProps}
                >
                    {isDragDropDisabled ? (
                        <div
                            data-tooltip-content="Clear search and filter to enable reorder"
                            data-tooltip-id={'drag-tooltip-' + banner.id}
                        >
                            ≡
                        </div>
                    ) : (
                        <>≡</>
                    )}
                </td>
                {isDragDropDisabled && (
                    // Needs to be outside <td> because of the styling
                    <Tooltip
                        id={'drag-tooltip-' + banner.id}
                        place="top-start"
                        delayShow={1000}
                    />
                )}
            </tr>
        )
    }

    const renderTable = (banners: Banner[]) => {
        return (
            <DragDropContext
                key={'banner_dragdropcontext'}
                onBeforeDragStart={getComputedSizes}
                onDragEnd={props.onDragEnd}
            >
                <Droppable droppableId="droppableBanner">
                    {(provided) => (
                        <tbody
                            key={'banners_tbody'}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {banners.map((banner, index) => (
                                <Draggable
                                    key={banner.id.toString()}
                                    draggableId={banner.id.toString()}
                                    index={index}
                                    isDragDisabled={isDragDropDisabled}
                                >
                                    {(provided, snapshot) =>
                                        getDraggableRow(
                                            provided,
                                            snapshot,
                                            banner,
                                            isDragDropDisabled
                                        )
                                    }
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </tbody>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    return (
        <table className={styles.bannersTable}>
            <thead>
                <tr>
                    <th>
                        <label htmlFor="name">Banner name</label>
                    </th>
                    <th>
                        <label htmlFor="status">Status</label>
                    </th>
                    <th>
                        <label htmlFor="linkId">Link ID</label>
                    </th>
                    <th>
                        <label htmlFor="start">Start</label>
                    </th>
                    <th>
                        <label htmlFor="end">End</label>
                    </th>
                    <th className={styles.alignRight}>
                        <label htmlFor="buttons"></label>
                    </th>
                    <th className={styles.alignRight}>
                        <label htmlFor="draghandle"></label>
                    </th>
                </tr>
            </thead>
            {renderTable(banners)}
        </table>
    )
}

export default BannersTable
