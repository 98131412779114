import { CSSProperties } from 'react'
import classNames from 'classnames'

import styles from './Spinner.module.scss'

export interface SpinnerProps {
    className?: string
    style?: CSSProperties
}

export const Spinner = ({ className, style }: SpinnerProps) => {
    return (
        <div style={style} className={classNames(styles.spinner, className)} />
    )
}
