import { ChangeEventHandler, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import { RouteTo } from '../../routes.ts'

import { BannerStatus } from '../../types/enums/bannerType.enum.ts'

import styles from './BannersHeader.module.scss'

const filterByStatusOptions = [
    'all',
    BannerStatus.ACTIVE,
    BannerStatus.INACTIVE,
    BannerStatus.DEFAULT,
]

type FilterByType = 'status'

const filterOptions: Record<FilterByType, string[]> = {
    status: filterByStatusOptions,
}
const filterOptionsDisplayNames: Record<
    FilterByType,
    // fixme We could define enum of possible filter options
    Record<string, string>
> = {
    status: {
        all: 'All',
        [BannerStatus.ACTIVE]: 'Active',
        [BannerStatus.INACTIVE]: 'Inactive',
        [BannerStatus.DEFAULT]: 'Default',
    },
}
const filterDisplayNames: Record<FilterByType, string> = {
    status: 'Status',
}

type BannersFiltersProps = {
    bannersCount: number
    searchString: string
    onSearchStringChange: ChangeEventHandler<HTMLInputElement>
    filterValues: { status: string }
    onFilterChange: ChangeEventHandler<HTMLInputElement>
}

export function BannersHeader(props: BannersFiltersProps) {
    const {
        bannersCount,
        searchString,
        onSearchStringChange,
        filterValues,
        onFilterChange,
    } = props

    const navigate = useNavigate()

    return (
        <>
            <div className={styles.header}>
                <div>
                    <h1>Banners ({bannersCount})</h1>
                    <button
                        className="new"
                        onClick={() => navigate(RouteTo.BannerNew())}
                    >
                        Add new
                    </button>
                </div>
                <input
                    aria-label="search banners"
                    autoFocus
                    className="editInput"
                    type="text"
                    value={searchString}
                    placeholder="Search..."
                    onChange={onSearchStringChange}
                />
            </div>
            <div className={styles.filters}>
                {(
                    Object.entries(filterOptions) as [FilterByType, string[]][]
                ).map(([filterBy, options]) => (
                    <fieldset key={filterBy}>
                        <legend>{filterDisplayNames[filterBy]}</legend>
                        {options.map((option) => (
                            <Fragment key={`${filterBy}-${option}`}>
                                <input
                                    defaultChecked={
                                        filterValues[filterBy] === option
                                    }
                                    id={`${filterBy}-${option}`}
                                    name={filterBy}
                                    type="radio"
                                    value={option}
                                    onChange={onFilterChange}
                                />
                                <label htmlFor={`${filterBy}-${option}`}>
                                    {
                                        filterOptionsDisplayNames[filterBy][
                                            option
                                        ]
                                    }
                                </label>
                            </Fragment>
                        ))}
                    </fieldset>
                ))}
            </div>
        </>
    )
}
