import _ from 'lodash'

import translations from './shared/translations.json'
import sharedUISchema from './shared/uiSchema.json'

import { RJSFSchema, UiSchema } from '@rjsf/utils'

import { LanguageType } from '../../types/enums/languagetype.enum'

export function resolveSchema(schema: RJSFSchema): RJSFSchema {
    const defSchema = { $def: { translations } }
    return _.merge(schema, defSchema)
}

function getTranslationsUISchema(
    activeLanguage: LanguageType,
    uiSchema: UiSchema
): UiSchema {
    return Object.values(LanguageType).reduce(
        (translationsUiSchema, lang) => ({
            ...translationsUiSchema,
            [lang]: {
                // TODO: disable editing translations via the widget
                'ui:options': { label: false },
                'ui:widget':
                    lang === activeLanguage ? uiSchema['ui:widget'] : 'hidden',
            },
        }),
        {}
    )
}

export function resolveUISchema(uiSchema: UiSchema, language: LanguageType) {
    const uiSchemaWithTranslations = _.cloneDeepWith(uiSchema, (value) => {
        if (value?.isTranslatable) {
            const translationsUISchema = getTranslationsUISchema(
                language,
                value
            )
            return { ...translationsUISchema, ...value }
        }
    })

    return _.merge(uiSchemaWithTranslations, sharedUISchema)
}
