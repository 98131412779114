import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { BannerDetails } from '../BannerDetails/BannerDetails.tsx'

import { useCreateBanner } from '../../hooks/banners.ts'

import { RouteTo } from '../../routes.ts'

import { LanguageType } from '../../types/enums/languagetype.enum.ts'

import { CreateOrUpdateBanner } from '../../types/bannerTypes'

import { createEmptyBanner } from '../emptyBanner.ts'

type BannerProps = { language: LanguageType }

export const BannerCreatePage = (props: BannerProps) => {
    const {
        bannerId,
        isLoading: isCreateBannerLoading,
        createBanner,
    } = useCreateBanner()
    const navigate = useNavigate()

    useEffect(() => {
        if (bannerId) {
            navigate(RouteTo.BannerEdit(bannerId.toString()))
        }
    }, [bannerId])

    async function onSave(banner: CreateOrUpdateBanner) {
        return createBanner(banner)
    }

    return (
        <BannerDetails
            language={props.language}
            isNew={true}
            onSave={onSave}
            initialBanner={createEmptyBanner()}
            isSaving={isCreateBannerLoading}
        />
    )
}
