import { CSSProperties, RefObject, useRef } from 'react'
import _ from 'lodash'

export type ComputedSize = {
    height: CSSProperties['height']
    width: CSSProperties['width']
}

export function useComputedSizes<T>(
    refs: Record<keyof T, RefObject<HTMLElement>>
) {
    const computedSizes = useRef<
        Record<keyof T, ComputedSize> | Record<string, never>
    >({})

    function getComputedSizes() {
        computedSizes.current = _.mapValues(refs, (ref) => {
            if (!ref.current) {
                return { height: undefined, width: undefined }
            }
            const { height, width } = window.getComputedStyle(ref.current)
            return { height, width }
        })
    }

    return {
        getComputedSizes,
        computedSizes: computedSizes,
    }
}
