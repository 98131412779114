import { SetStateAction, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useSearchParamState = <Value>(
    initialState: Value,
    key: string
) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const valueInSearch = searchParams.get(key)
    const value: Value =
        valueInSearch !== null ? JSON.parse(valueInSearch) : initialState

    const setSearchParamState = useCallback(
        (action: SetStateAction<Value>, other?: Record<string, unknown>) => {
            let nextValue: Value

            if (action instanceof Function) {
                nextValue = action(value)
            } else {
                nextValue = action
            }

            setSearchParams(
                (oldParams) => {
                    const params = new URLSearchParams(oldParams)
                    params.set(key, JSON.stringify(nextValue))
                    for (const key in other) {
                        params.set(key, JSON.stringify(other[key]))
                    }
                    return params
                },
                {
                    replace: true,
                }
            )
        },
        [value, key, setSearchParams]
    )

    return [value, setSearchParamState] as const
}
