import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Form from '@rjsf/core'
import validator from '@rjsf/validator-ajv8'
import _ from 'lodash'

import { Button } from '../shared/Button'
import Selector from '../shared/Selector'
import { LabelInput, Loading, Section } from '../shared'

import {
    Post as PostType,
    useCreatePortalPost,
    usePortalPost,
    useUpdatePortalPost,
} from '../hooks/portal'
import { useSelectedLanguage } from '../hooks/selectedLanugage'
import { useTranslations } from '../hooks/translations'

import { assert } from '../utils/assert'
import { handleError } from '../utils/utils'
import { resolveSchema, resolveUISchema } from './schemas/utils'

import { RouteTo } from '../routes'

import { isIndexable } from '../types/indexable'

import styles from './Post.module.scss'
import { schemas, uiSchemas } from './schemas'
import {
    applyTranslations,
    getTranslatableFields,
    getTranslationKey,
    getTranslationState,
} from './translationUtils'
import widgets from './widgets'

function Post() {
    const { id } = useParams()
    const navigate = useNavigate()
    const { data: post, error: postError } = usePortalPost(id)
    const { mutate: createPost } = useCreatePortalPost()
    const { mutate: updatePost } = useUpdatePortalPost()

    // Redirect to the list view if fetching failed
    useEffect(() => {
        if (postError) {
            handleError('Failed to fetch the post', postError)
            navigate(RouteTo.Portal())
        }
    }, [postError])

    if (id && !post) {
        return <Loading />
    }

    return (
        <PostInternal
            id={id}
            post={post}
            createPost={createPost}
            updatePost={updatePost}
        />
    )
}

type PostInternalProps = {
    id?: string
    post?: PostType
    createPost: ReturnType<typeof useCreatePortalPost>['mutate']
    updatePost: ReturnType<typeof useUpdatePortalPost>['mutate']
}

function PostInternal(props: PostInternalProps) {
    const { id, post, createPost, updatePost } = props

    const { selectedLanguage } = useSelectedLanguage()
    const navigate = useNavigate()
    const [draftId, setDraftId] = useState(id ?? '')
    const defaultTemplateId = Object.keys(schemas)[0]
    const [draftTemplateId, setDraftTemplateId] = useState<
        keyof typeof schemas
    >(post?.templateId ?? defaultTemplateId)
    const [draftPayload, setDraftPayload] = useState<Record<string, unknown>>(
        post?.payload ?? {}
    )

    const onClickSave = (
        templateId: string,
        payload: Record<string, unknown>
    ) => {
        if (id) {
            return updatePost(
                {
                    postId: id,
                    updatePostDto: { id, templateId, payload },
                },
                {
                    onSuccess: (post) => {
                        setDraftTemplateId(post.templateId)
                        setDraftPayload(post.payload)
                    },
                    onError: (err) =>
                        handleError('Failed to update the post', err),
                }
            )
        }
        createPost(
            { id: draftId, templateId, payload },
            {
                onSuccess: (post) => {
                    setDraftTemplateId(post.templateId)
                    setDraftPayload(post.payload)
                    navigate(RouteTo.PortalPostEdit(post.id), { replace: true })
                },
                onError: (err) => handleError('Failed to create the post', err),
            }
        )
    }

    const schema = draftTemplateId
        ? resolveSchema(schemas[draftTemplateId])
        : {}
    const uiSchema = draftTemplateId
        ? resolveUISchema(uiSchemas[draftTemplateId], selectedLanguage)
        : {}

    const translatableFields = useMemo(
        () => getTranslatableFields(uiSchema),
        [uiSchema]
    )
    const {
        data: translations,
        isFetching: isFetchingTranslations,
        refetch: refetchTranslations,
    } = useTranslations('CMS', id ? getTranslationKey(id) : undefined)

    const translationState = useMemo(() => {
        const postId = post?.id
        if (!postId || !translations) {
            return
        }
        return getTranslationState(
            postId,
            translatableFields,
            draftPayload,
            translations
        )
    }, [post?.templateId, translations, translatableFields, draftPayload])

    const onLoadTranslations = () => {
        const postId = post?.id
        if (!postId || !translations) {
            return
        }

        setDraftPayload((payload) =>
            applyTranslations(postId, translatableFields, payload, translations)
        )
    }

    const copyTranslationTable = () => {
        if (!post) {
            return
        }

        const translationValues = getTranslatableFields(uiSchema).map(
            (fieldPath) => {
                return {
                    key: getTranslationKey(post.id, fieldPath),
                    path: fieldPath,
                }
            }
        )

        const table = translationValues
            .map((value) => {
                const translationTable = _.get(draftPayload, value.path)
                assert(
                    isIndexable(translationTable) &&
                        !Array.isArray(translationTable),
                    "A translatable field doesn't have a translation table stored in it"
                )
                return `${value.key}\t\t${translationTable['en'] ?? ''}`
            })
            .join('\n')

        navigator.clipboard.writeText(table)
    }

    return (
        <>
            <Section title="Metadata">
                <LabelInput
                    size="semiwide"
                    label="Name"
                    name="post-name"
                    disabled={Boolean(id)}
                    value={id ?? draftId}
                    onChange={(e) => setDraftId(e.currentTarget.value)}
                />
            </Section>
            <Section title="Content">
                <Selector
                    className={styles.selector}
                    onChange={(e) => {
                        setDraftTemplateId(e.currentTarget.value)
                        setDraftPayload({})
                    }}
                    value={draftTemplateId}
                >
                    {Object.keys(schemas).map((templateId) => (
                        <option key={templateId} value={templateId}>
                            {uiSchemas[templateId]['ui:title']}
                        </option>
                    ))}
                </Selector>
                <Form
                    schema={schema}
                    uiSchema={uiSchema}
                    widgets={widgets}
                    validator={validator}
                    formData={draftPayload}
                    onChange={(e) => setDraftPayload(e.formData ?? {})}
                />
                {id && (
                    <div>
                        <fieldset className={styles.translationsContainer}>
                            <legend
                                className={styles.translationsHeaderContainer}
                            >
                                Translations
                                <Button
                                    onClick={copyTranslationTable}
                                    color="secondary"
                                    size="small"
                                >
                                    Copy Keys
                                </Button>
                            </legend>
                            {translationState?.hasNewTranslations ? (
                                <>
                                    <p>⚠️ There are unsynced translations</p>
                                    <Button
                                        onClick={onLoadTranslations}
                                        color="primary"
                                    >
                                        Load from Google Sheets
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <div>
                                        {translationState &&
                                        translationState.missingTranslations
                                            .length > 0 ? (
                                            <>
                                                <p>
                                                    ⚠️ There are translations
                                                    missing for:
                                                </p>
                                                <ul>
                                                    {translationState.missingTranslations.map(
                                                        ({
                                                            translationKey,
                                                            missingLanguages,
                                                        }) => (
                                                            <li
                                                                key={
                                                                    translationKey
                                                                }
                                                            >
                                                                {translationKey}{' '}
                                                                <span
                                                                    title={missingLanguages.join(
                                                                        ', '
                                                                    )}
                                                                >
                                                                    (
                                                                    {
                                                                        missingLanguages.length
                                                                    }{' '}
                                                                    missing
                                                                    translations)
                                                                </span>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </>
                                        ) : isFetchingTranslations ? (
                                            <p>
                                                Checking for new translations...
                                            </p>
                                        ) : (
                                            <p>
                                                ✅ Translations are synchronized
                                            </p>
                                        )}
                                    </div>
                                    <Button
                                        onClick={() => refetchTranslations()}
                                        loading={isFetchingTranslations}
                                        color="secondary"
                                    >
                                        Check again
                                    </Button>
                                </>
                            )}
                        </fieldset>
                    </div>
                )}
                {/* TODO: implement better save flow and validation */}
                <button
                    disabled={!draftTemplateId}
                    onClick={() =>
                        draftTemplateId &&
                        onClickSave(draftTemplateId, draftPayload)
                    }
                >
                    Save
                </button>
            </Section>
        </>
    )
}

export default Post
