import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import IconAddPlus from '../icons/IconAddPlus.svg'
import { Button } from '../shared/Button'
import { Chip, ChipProps } from '../shared/Chip'
import Pagination from '../shared/pagination/Pagination'
import { TableColumn } from '../shared/Table/TableColumn'
import { createSortFn, SortDescriptor } from '../shared/Table/TableUtils'
import { LabelInput } from '../shared'

import { usePortalPosts } from '../hooks/portal'
import { useSearchParamState } from '../hooks/useSearchParamState'

import { RouteTo } from '../routes'

import styles from './PortalPostLibrary.module.scss'
import { getPublicationStatus } from './portalUtils'

const PAGE_SIZE = 10

type PostStatus = 'published' | 'scheduled' | 'expired' | 'unused'

const POST_STATUS_COLOR_MAP: Record<PostStatus, ChipProps['color']> = {
    published: 'primary',
    scheduled: 'default',
    expired: 'secondary',
    unused: 'secondary',
}
const POST_STATUS_DISPLAY_MAP: Record<PostStatus, string> = {
    published: 'In publication feed',
    scheduled: 'Scheduled',
    expired: 'Expired',
    unused: 'Not in use',
}

export const PortalPostLibrary = () => {
    const navigate = useNavigate()
    const { data: posts, deletePost } = usePortalPosts()

    const tableData = useMemo(() => {
        return (posts ?? []).map((post) => ({
            id: post.id,
            name: post.id,
            type: post.templateId,
            status: post.publications
                .map(getPublicationStatus)
                .reduce<PostStatus>((prev, status) => {
                    // If multiple publications, prefer published > scheduled > expired
                    const tuple = [prev, status]
                    if (tuple.includes('published')) {
                        return 'published'
                    }
                    if (tuple.includes('scheduled')) {
                        return 'scheduled'
                    }
                    if (tuple.includes('expired')) {
                        return 'expired'
                    }
                    return 'unused'
                }, 'unused'),
        }))
    }, [posts])

    const [sortDescriptor, setSortDescriptor] =
        useSearchParamState<SortDescriptor<(typeof tableData)[number]> | null>(
            null,
            'sort'
        )
    const [searchValue, setSearchValue] = useSearchParamState('', 'search')
    const [page] = useSearchParamState(1, 'page')

    const onSearchUpdate = (value: string) => {
        setSearchValue(value, { page: 1 })
    }

    const filteredData = useMemo(() => {
        let filteredData = tableData
        const normalizedSearchValue = searchValue.toLowerCase()
        if (searchValue) {
            filteredData = filteredData?.filter(
                (post) =>
                    post.type.toLowerCase().includes(normalizedSearchValue) ||
                    post.name.toLowerCase().includes(normalizedSearchValue)
            )
        }
        if (sortDescriptor) {
            filteredData = filteredData?.toSorted(createSortFn(sortDescriptor))
        }

        return filteredData
    }, [tableData, searchValue, sortDescriptor])

    const paginatedData = useMemo(() => {
        return filteredData?.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
    }, [filteredData, page])

    return (
        <>
            <aside className={styles.createButtonContainer}>
                <Button
                    className={styles.createButton}
                    onClick={() => navigate(RouteTo.PortalPostNew())}
                    startContent={<img src={IconAddPlus} />}
                >
                    Create post
                </Button>
            </aside>

            <menu className={styles.menu}>
                <LabelInput
                    value={searchValue}
                    label="Search"
                    size="normal"
                    onChange={(e) => onSearchUpdate(e.target.value)}
                />
            </menu>

            {posts && filteredData && posts.length !== filteredData.length && (
                <p>
                    (Showing {filteredData.length} out of {posts.length} posts)
                </p>
            )}
            <table className={styles.table}>
                <thead>
                    <tr>
                        <TableColumn
                            allowSorting
                            activeSortDescriptor={sortDescriptor}
                            onSortChange={setSortDescriptor}
                            column="name"
                        >
                            Name
                        </TableColumn>
                        <TableColumn
                            allowSorting
                            activeSortDescriptor={sortDescriptor}
                            onSortChange={setSortDescriptor}
                            column="type"
                        >
                            Template
                        </TableColumn>
                        <TableColumn
                            allowSorting
                            activeSortDescriptor={sortDescriptor}
                            onSortChange={setSortDescriptor}
                            column="status"
                        >
                            Status
                        </TableColumn>
                        <th>{/* Actions */}</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedData?.map((post) => {
                        return (
                            <tr key={post.id}>
                                <td>{post.name}</td>
                                <td>{post.type ?? 'Unknown'}</td>
                                <td>
                                    <Chip
                                        color={
                                            POST_STATUS_COLOR_MAP[post.status]
                                        }
                                    >
                                        {POST_STATUS_DISPLAY_MAP[post.status]}
                                    </Chip>
                                </td>
                                <td className={styles.actionsContainer}>
                                    <Button
                                        onClick={() =>
                                            navigate(
                                                RouteTo.PortalPostEdit(post.id)
                                            )
                                        }
                                        color="primary"
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        color="danger"
                                        onClick={() => {
                                            deletePost.mutate(post.id)
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {filteredData && filteredData.length > PAGE_SIZE && (
                <Pagination
                    currentPage={page}
                    totalPageCount={Math.ceil(
                        (filteredData?.length ?? 0) / PAGE_SIZE
                    )}
                />
            )}
        </>
    )
}
