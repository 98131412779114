import styles from './ToggleSwitch.module.scss'

type ToggleSwitchProps = {
    checked: boolean
    onToggle: () => void
}

export const ToggleSwitch = ({ checked, onToggle }: ToggleSwitchProps) => {
    return (
        <label className={styles.toggleSwitch}>
            <input
                type="checkbox"
                checked={checked}
                onChange={onToggle}
                className={styles.toggleCheckbox}
            />
            <span className={styles.toggleSlider} />
        </label>
    )
}
