import { ChangeEventHandler, MouseEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OnDragEndResponder } from '@hello-pangea/dnd'
import axios from 'axios'
import _ from 'lodash'

import { BannersHeader } from '../BannersHeader/BannersHeader.tsx'
import BannersTable from '../BannersTable/BannersTable.tsx'
import { Loading, Modal, Section } from '../../shared'

import { useBanners } from '../../hooks/banners.ts'

import { handleError, moveListItem } from '../../utils/utils.ts'
import { filterBannersBySearchString, filterBannersByStatus } from '../utils.ts'

import { RouteTo } from '../../routes.ts'

import { Banner } from '../../types/bannerTypes'

export function Banners() {
    const navigate = useNavigate()
    const [banners, setBanners] = useState<Banner[]>([])
    const { banners: fetchedBanners, error, fetch, isLoading } = useBanners()

    const [filterByStatus, setFilterByStatus] = useState(
        localStorage.getItem('bannersFilterByStatus') ?? 'all'
    )
    const [searchString, setSearchString] = useState(
        localStorage.getItem('bannersSearchString') ?? ''
    )

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        setBanners(fetchedBanners)
    }, [fetchedBanners])

    const onFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.target
        if (name === 'status') {
            setFilterByStatus(value)
        }
        const itemKey =
            'bannersFilterBy' + name.charAt(0).toUpperCase() + name.substring(1)
        localStorage.setItem(itemKey, value)
    }

    const onSearchStringChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value
        localStorage.setItem('bannersSearchString', value)
        setSearchString(value)
    }

    const redirectToBannerDetails = (id: Banner['id']) => {
        navigate(RouteTo.BannerEdit(String(id)))
    }
    const copyBanner = () => undefined
    const deleteBanner = async (
        e: MouseEvent<HTMLButtonElement>,
        bannerId: Banner['id']
    ) => {
        e.stopPropagation()

        const isConfirmed = await Modal.confirm({
            heading: 'Delete banner',
            text: 'Really delete this banner?',
            okLabel: 'Yes do it!',
        })

        if (!isConfirmed) return

        try {
            await axios.delete('/api/v2/banners/' + bannerId)

            await fetch()
        } catch (error) {
            handleError('Error deleting banner', error)
        }
    }

    const saveBannersOrder = async (ids: number[]) => {
        try {
            await axios.put('/api/v2/banners/priorities', { ids })
        } catch (error) {
            handleError('Error reordering banners', error, true)
        }
    }

    const onDragEnd: OnDragEndResponder = async (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        if (result.destination.index === result.source.index) {
            return
        }

        const reorderedBanners = moveListItem(
            banners,
            result.source.index,
            result.destination.index
        )
        setBanners(reorderedBanners)

        const ids = reorderedBanners.map((banner) => banner.id)
        await saveBannersOrder(ids)
        // Fetch updated list of banners
        await fetch()
    }

    const isDragDropDisabled =
        isLoading || !!searchString || filterByStatus !== 'all'

    if (isLoading && !banners.length) {
        return <Loading />
    }

    if (!banners.length) {
        return <Section>No banners</Section>
    }

    if (error) {
        return <Section>{error}</Section>
    }

    const filterBanners = _.flow([
        (banners) => filterBannersBySearchString(banners, searchString),
        (banners) => filterBannersByStatus(banners, filterByStatus),
    ])
    const filteredBanners = filterBanners(banners)

    return (
        <>
            <Section>
                <BannersHeader
                    bannersCount={filteredBanners.length}
                    searchString={searchString}
                    onSearchStringChange={onSearchStringChange}
                    filterValues={{
                        status: filterByStatus,
                    }}
                    onFilterChange={onFilterChange}
                />
                <BannersTable
                    banners={filteredBanners}
                    onBannerClick={redirectToBannerDetails}
                    onDragEnd={onDragEnd}
                    onCopyBannerClick={copyBanner}
                    onDeleteBannerClick={deleteBanner}
                    isDragDropDisabled={isDragDropDisabled}
                />
            </Section>
        </>
    )
}
