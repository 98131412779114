import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import { BannerDetails } from '../BannerDetails/BannerDetails.tsx'
import { Loading, Modal, Section } from '../../shared'

import { useBanner, useUpdateBanner } from '../../hooks/banners.ts'

import { handleError } from '../../utils/utils.ts'

import { RouteTo } from '../../routes.ts'

import { LanguageType } from '../../types/enums/languagetype.enum.ts'

import { CreateOrUpdateBanner } from '../../types/bannerTypes'

type BannerProps = { language: LanguageType }

export const BannerUpdatePage = (props: BannerProps) => {
    const navigate = useNavigate()
    const params = useParams()
    const bannerId = params.id as string

    const {
        banner: fetchedBanner,
        fetch: fetchBanner,
        error: fetchBannerError,
        isLoading: isFetchBannerLoading,
    } = useBanner(bannerId)
    const { isLoading: isUpdateBannerLoading, updateBanner } =
        useUpdateBanner(bannerId)

    useEffect(() => {
        fetchBanner()
    }, [])

    async function onSave(banner: CreateOrUpdateBanner) {
        return updateBanner(banner)
    }

    const deleteBanner = async () => {
        const isConfirmed = await Modal.confirm({
            heading: 'Delete banner',
            text: 'Really delete this banner?',
            okLabel: 'Yes do it!',
        })

        if (!isConfirmed) return

        try {
            await axios.delete('/api/v2/banners/' + bannerId)

            navigate(RouteTo.Banners())
        } catch (error) {
            handleError('Error deleting banner', error)
        }
    }

    if (isFetchBannerLoading) {
        return <Loading />
    }

    if (!fetchedBanner) {
        return <Section>The banner does not exist</Section>
    }

    if (fetchBannerError) {
        return <Section>{fetchBannerError}</Section>
    }

    return (
        <BannerDetails
            language={props.language}
            isNew={false}
            onSave={onSave}
            isSaving={isUpdateBannerLoading}
            initialBanner={fetchedBanner}
            onDelete={deleteBanner}
        />
    )
}
