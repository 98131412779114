import { useState } from 'react'
import classNames from 'classnames'

import { DraggablePhotos } from '../../../shared'

import { assert } from '../../../utils/assert'
import {
    OnCompleteCallback,
    OnPhotosSelectedCallback,
    onSelectPhoto,
    onSelectVideo,
    OnVideoUploadComplete,
    VideoFileUploading,
} from '../../../utils/upload'
import { WidgetProps } from '@rjsf/utils'

import { MediaType } from '../../../types/enums/mediatype.enum'

import styles from './File.module.scss'

import dogImg from '../../../assets/dog.png'

function File(props: WidgetProps) {
    const { id, value, label, required: isRequired, onChange, uiSchema } = props
    const [isUploading, setIsUploading] = useState(false)

    const onPhotoSelected: OnPhotosSelectedCallback = (_type, fileList) => {
        assert(fileList.length === 1)
        onChange(fileList[0].url)
        setIsUploading(true)
    }
    const onPhotoUpload: OnCompleteCallback = (_type, _file, res) => {
        onChange(res.data.uploaded.url)
        setIsUploading(false)
    }

    function onVideoSelected(fileUploading: VideoFileUploading) {
        onChange(fileUploading.url)
        setIsUploading(true)
    }
    const onVideoUpload: OnVideoUploadComplete = (_file, res) => {
        assert(res.data.uploaded.length === 1)
        onChange(res.data.uploaded[0].url)
        setIsUploading(false)
    }

    const fileType = uiSchema?.fileType
    const isDisabled = isUploading || Boolean(value)
    return (
        <fieldset>
            <legend>
                {label}
                {isRequired && '*'}
            </legend>
            {!value && (
                <div className="noMediaWrapper">
                    <img alt="placeholder no uploaded video" src={dogImg} />
                    <label className="placeholder">
                        {`No ${fileType}? Ohno. Click below to upload :)`}
                    </label>
                </div>
            )}
            {value && (
                <DraggablePhotos
                    type={
                        fileType === 'image'
                            ? MediaType.PORTAL
                            : MediaType.VIDEO
                    }
                    items={[{ id: value, url: value, uploading: isUploading }]}
                    onDelete={() => onChange(undefined)}
                />
            )}
            <div className={styles.fileInput}>
                <input
                    type="file"
                    name={id}
                    id={id}
                    accept={`${fileType}/*`}
                    disabled={isDisabled}
                    multiple={false}
                    onChange={(e) =>
                        fileType === 'image'
                            ? onSelectPhoto(
                                  e,
                                  MediaType.PORTAL,
                                  onPhotoSelected,
                                  onPhotoUpload
                              )
                            : onSelectVideo(e, onVideoSelected, onVideoUpload)
                    }
                />
                <label
                    className={classNames('upload', isDisabled && 'disabled')}
                    htmlFor={id}
                >
                    Add new
                </label>
            </div>
        </fieldset>
    )
}

export default File
