import { RJSFSchema, UiSchema } from '@rjsf/utils'

import articleInteractiveLarge from './articleInteractiveLarge/schema.json'
import articleInteractiveLargeUI from './articleInteractiveLarge/uiSchema.json'
import articleInteractiveMedium from './articleInteractiveMedium/schema.json'
import articleInteractiveMediumUI from './articleInteractiveMedium/uiSchema.json'
import articleStaticLarge from './articleStaticLarge/schema.json'
import articleStaticLargeUI from './articleStaticLarge/uiSchema.json'
import articleStaticMedium from './articleStaticMedium/schema.json'
import articleStaticMediumUI from './articleStaticMedium/uiSchema.json'
import linkLocationLarge from './linkLocationLarge/schema.json'
import linkLocationLargeUI from './linkLocationLarge/uiSchema.json'
import linkLocationMedium from './linkLocationMedium/schema.json'
import linkLocationMediumUI from './linkLocationMedium/uiSchema.json'
import linkShopLarge from './linkShopLarge/schema.json'
import linkShopLargeUI from './linkShopLarge/uiSchema.json'
import linkShopMedium from './linkShopMedium/schema.json'
import linkShopMediumUI from './linkShopMedium/uiSchema.json'
import videoLarge from './videoLarge/schema.json'
import videoLargeUI from './videoLarge/uiSchema.json'
import videoMedium from './videoMedium/schema.json'
import videoMediumUI from './videoMedium/uiSchema.json'

export const schemas: Record<string, RJSFSchema> = {
    'article-interactive-medium': articleInteractiveMedium as RJSFSchema,
    'article-interactive-large': articleInteractiveLarge as RJSFSchema,
    'article-static-medium': articleStaticMedium as RJSFSchema,
    'article-static-large': articleStaticLarge as RJSFSchema,
    'link-shop-medium': linkShopMedium as RJSFSchema,
    'link-shop-large': linkShopLarge as RJSFSchema,
    'link-location-medium': linkLocationMedium as RJSFSchema,
    'link-location-large': linkLocationLarge as RJSFSchema,
    'video-medium': videoMedium as RJSFSchema,
    'video-large': videoLarge as RJSFSchema,
}

export const uiSchemas: Record<string, UiSchema> = {
    'article-interactive-medium': articleInteractiveMediumUI,
    'article-interactive-large': articleInteractiveLargeUI,
    'article-static-medium': articleStaticMediumUI,
    'article-static-large': articleStaticLargeUI,
    'link-shop-medium': linkShopMediumUI,
    'link-shop-large': linkShopLargeUI,
    'link-location-medium': linkLocationMediumUI,
    'link-location-large': linkLocationLargeUI,
    'video-medium': videoMediumUI,
    'video-large': videoLargeUI,
}
