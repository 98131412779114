import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import _ from 'lodash'

import { LanguageType } from '../types/enums/languagetype.enum'

import { useLocalStorageState } from './useLocalStorageState'

const SelectedLanguageContext = createContext<{
    selectedLanguage: LanguageType
    setSelectedLanguage: (language: LanguageType) => void
}>({ selectedLanguage: LanguageType.English, setSelectedLanguage: _.noop })

export const SelectedLanguageProvider = ({ children }: PropsWithChildren) => {
    const [selectedLanguage, setSelectedLanguage] =
        useLocalStorageState<LanguageType>(LanguageType.English, 'language')

    const value = useMemo(() => {
        return {
            selectedLanguage,
            setSelectedLanguage,
        }
    }, [selectedLanguage])

    return (
        <SelectedLanguageContext.Provider value={value}>
            {children}
        </SelectedLanguageContext.Provider>
    )
}

export const useSelectedLanguage = () => {
    return useContext(SelectedLanguageContext)
}
