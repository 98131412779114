import { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react'
import { AxiosResponse } from 'axios'
import _, { noop } from 'lodash'
import moment from 'moment'

import { SaveStatus } from '../../shared/SavePanel.tsx'
import { ToggleSwitch } from '../../shared/ToggleSwitch/ToggleSwitch.tsx'
import {
    BannerAction,
    PossibleBannerActionParameters,
} from '../BannerAction/BannerAction.tsx'
import { BannerMedia } from '../BannerMedia/BannerMedia.tsx'
import { LabelInput, NewRow, SavePanel, Section } from '../../shared'

import { toHTMLDate, toJSONDate } from '../../utils/dates.ts'
import { ImageFileUploading } from '../../utils/upload'
import { languages } from '../../utils/utils.ts'

import { LanguageType } from '../../types/enums/languagetype.enum.ts'
import { MediaType } from '../../types/enums/mediatype.enum.ts'

import { Banner, CreateOrUpdateBanner } from '../../types/bannerTypes'
import { Media } from '../../types/mediaTypes'

import { EmptyBanner } from '../emptyBanner.ts'
import validate from '../validate.ts'
import styles from './BannerDetails.module.scss'

export enum PossibleBannerFields {
    Name = 'name',
    Title = 'title',
    Subtitle = 'subtitle',
    ActiveFromDate = 'activeFromDate',
    ActiveToDate = 'activeToDate',
}

type BannerDetailsProps = {
    language: LanguageType
    isNew: boolean
    initialBanner: Banner | EmptyBanner
    onSave: (banner: CreateOrUpdateBanner) => Promise<void>
    onDelete?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
    isSaving: boolean
}

type MediaOrImageFileUploading = Media | (ImageFileUploading & { id?: number })

export const BannerDetails = (props: BannerDetailsProps) => {
    const {
        language,
        isNew,
        initialBanner,
        isSaving,
        onSave,
        onDelete = () => Promise.resolve(),
    } = props
    const [banner, setBanner] = useState<Banner | EmptyBanner>(initialBanner)
    const [backgroundMedia, setBackgroundMedia] = useState<
        MediaOrImageFileUploading | undefined
    >('backgroundMedia' in banner ? banner.backgroundMedia : undefined)
    const [isSavedStatusShown, setIsSavedStatusShown] = useState<boolean>(false)

    const hasUnsavedChanges = useMemo(() => {
        const existingBackgroundMedia =
            'backgroundMedia' in banner ? banner.backgroundMedia : undefined

        return (
            !_.isEqual(banner, initialBanner) ||
            !_.isEqual(existingBackgroundMedia, backgroundMedia)
        )
    }, [banner, initialBanner, backgroundMedia])

    const setHasUnsavedChanges = (hasUnsavedChanges: boolean) => {
        localStorage.setItem('hasUnsavedChanges', String(hasUnsavedChanges))
    }

    useEffect(() => {
        setHasUnsavedChanges(hasUnsavedChanges)
    }, [hasUnsavedChanges])

    useEffect(() => {
        return () => {
            // Executed on the component unmount
            setHasUnsavedChanges(false)
        }
    }, [])

    useEffect(() => {
        if (isSavedStatusShown) {
            setTimeout(() => {
                setIsSavedStatusShown(false)
            }, 2000)
        }
    }, [isSavedStatusShown])

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name as
            | PossibleBannerFields
            | PossibleBannerActionParameters
        const value: string | number = e.target.value
        const data = _.cloneDeep(banner)

        if (
            name === PossibleBannerFields.Title ||
            name === PossibleBannerFields.Subtitle
        ) {
            data[name] = data[name] || {}
            ;(data[name] as Record<LanguageType, string>)[language] = value
        } else if (
            name === PossibleBannerFields.ActiveFromDate ||
            name === PossibleBannerFields.ActiveToDate
        ) {
            ;(data[name] as string) = toJSONDate(value)
        } else if (name === PossibleBannerActionParameters.PackId) {
            data.actionParameters = data.actionParameters || {}

            data.actionParameters[name as PossibleBannerActionParameters] =
                value.trim()
        } else {
            ;(data[name as PossibleBannerFields] as string) = value.trim()
        }

        setBanner(data)
    }

    const onIsDefaultToggle = () => {
        setBanner((banner) => {
            const isDefault = !banner.isDefault

            if (isDefault) {
                return {
                    ...banner,
                    activeFromDate: toJSONDate(
                        moment().startOf('day').valueOf()
                    ),
                    activeToDate: toJSONDate(new Date(2050, 11, 31)),
                    isDefault,
                }
            }

            return {
                ...banner,
                activeFromDate: toJSONDate(initialBanner?.activeFromDate),
                activeToDate: toJSONDate(initialBanner?.activeToDate),
                isDefault,
            }
        })
    }

    const onPhotosSelected = (
        _type: MediaType,
        files: ImageFileUploading[]
    ) => {
        if (!files.length) {
            return
        }

        setBackgroundMedia(files[0])
    }

    const onPhotosWasUploaded = (
        _type: MediaType,
        _file: File,
        res: AxiosResponse<{ uploaded: { id: number } }>
    ) => {
        setBackgroundMedia(
            _.assign(res.data.uploaded as Media, {
                uploading: false,
            })
        )
    }

    const getSaveStatus = (): SaveStatus => {
        if (isSaving) {
            return SaveStatus.SAVING
        }

        if (hasUnsavedChanges) {
            return SaveStatus.HAS_CHANGED
        }

        return SaveStatus.NO_CHANGES
    }

    const getBannerCreateUpdateBody = (): CreateOrUpdateBanner => {
        const body: CreateOrUpdateBanner = {
            ..._.omit(banner as Banner, ['backgroundMedia', 'id']),
            actionType: banner.actionParameters?.packId
                ? 'open_pdp'
                : 'open_shop',
            backgroundMediaId:
                backgroundMedia?.id ||
                (banner as Banner).backgroundMedia?.id ||
                NaN,
        }

        return body
    }

    const save = async () => {
        await onSave(getBannerCreateUpdateBody())

        setHasUnsavedChanges(false)

        setIsSavedStatusShown(true)
    }

    const mediaItems = [
        backgroundMedia,
        (initialBanner as Banner).backgroundMedia,
    ]
        .filter((m) => !!m)
        .slice(0, 1) as MediaOrImageFileUploading[]

    const validationResults = validate(getBannerCreateUpdateBody())

    return (
        <>
            <Section title={isNew ? `New Banner: ${banner.name}` : banner.name}>
                <LabelInput
                    size="semiwide"
                    name={PossibleBannerFields.Name}
                    value={banner.name || ''}
                    tooltip="Internal use"
                    label="Banner name"
                    onChange={onInputChange}
                />
                <NewRow />
                <LabelInput
                    size="semiwide"
                    name={PossibleBannerFields.Title}
                    value={banner.title[language] || ''}
                    tooltip=""
                    label="Banner title in "
                    labelHighlight={languages[language]}
                    onChange={onInputChange}
                />
                <LabelInput
                    size="semiwide"
                    name={PossibleBannerFields.Subtitle}
                    value={
                        banner.subtitle ? banner.subtitle[language] || '' : ''
                    }
                    tooltip="Optional"
                    label="Banner subtitle in "
                    labelHighlight={languages[language]}
                    onChange={onInputChange}
                />
            </Section>
            <Section title="Banner behaviour">
                <div className={styles.defaultToggle}>
                    <ToggleSwitch
                        checked={banner.isDefault}
                        onToggle={onIsDefaultToggle}
                    />
                    Set as default
                </div>

                <LabelInput
                    size="normal"
                    type="datetime-local"
                    name={PossibleBannerFields.ActiveFromDate}
                    value={toHTMLDate(banner.activeFromDate)}
                    disabled={banner.isDefault}
                    label="Starts"
                    onChange={onInputChange}
                />
                <LabelInput
                    size="normal"
                    type="datetime-local"
                    name={PossibleBannerFields.ActiveToDate}
                    value={toHTMLDate(banner.activeToDate)}
                    disabled={banner.isDefault}
                    label="Ends"
                    onChange={onInputChange}
                />
            </Section>
            <Section title="Banner media">
                <BannerMedia
                    type={MediaType.BANNER_MOON_BACKGROUND}
                    header={'Banner background image (640 x 640, PNG)'}
                    filename={banner.name}
                    items={mediaItems}
                    onMediaSelect={onPhotosSelected}
                    onMediaWasUploaded={onPhotosWasUploaded}
                    onMediaDragEnd={noop}
                    buttonLabel={mediaItems.length ? 'Replace' : 'Add new'}
                />
            </Section>
            <Section title="Banner link">
                <BannerAction
                    parameters={banner.actionParameters}
                    onInputChange={onInputChange}
                />
            </Section>
            <SavePanel
                title={`Working with: ${banner.name || 'no name'}`}
                validationResults={validationResults}
                item={banner}
                save={save}
                showDelete={!isNew}
                saveStatus={
                    isSavedStatusShown ? SaveStatus.SAVED : getSaveStatus()
                }
                onDelete={onDelete}
            />
        </>
    )
}
