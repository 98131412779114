import _ from 'lodash'

import { Translations } from '../hooks/translations'

import { LanguageType } from '../types/enums/languagetype.enum'

import { Indexable, isIndexable } from '../types/indexable'

type Path = Array<string | number>

export function getTranslatableFields(schema: Indexable): Array<Path> {
    const paths: Array<Path> = []

    for (const key in schema) {
        const value = Array.isArray(schema) ? schema[Number(key)] : schema[key]

        if (
            value !== null &&
            typeof value === 'object' &&
            'isTranslatable' in value &&
            typeof value.isTranslatable === 'boolean' &&
            value.isTranslatable
        ) {
            paths.push([key])
        } else if (isIndexable(value)) {
            paths.push(
                ...getTranslatableFields(value).map((path) => [key, ...path])
            )
        }
    }

    return paths
}

export function getTranslationKey(prefix: string, path?: Path): string {
    const normalizedPrefix = prefix.replaceAll(/\s/g, '_')
    if (path && path.length > 0) {
        return `${normalizedPrefix}.${path[path.length - 1]}`
    }
    return normalizedPrefix
}

export function getTranslationState(
    prefix: string,
    translatableFields: Array<Path>,
    payload: Record<string, unknown>,
    translations: Translations
) {
    const hasNewTranslations = translatableFields.some((path) => {
        const translationKey = getTranslationKey(prefix, path)
        const current = _.get(payload, path) ?? {}
        const latest = translations[translationKey] ?? {}

        for (const lang in latest) {
            if (current[lang] !== latest[lang]) {
                return true
            }
        }

        return false
    })

    const missingTranslations = translatableFields
        .map((path) => getTranslationKey(prefix, path))
        .map((translationKey) => {
            const latest = translations[translationKey] ?? {}

            const missingLanguages = Object.values(LanguageType).filter(
                (language) => {
                    return !latest[language]
                }
            )

            return { translationKey, missingLanguages }
        })
        .filter(({ missingLanguages }) => missingLanguages.length > 0)

    return {
        hasNewTranslations,
        missingTranslations,
    }
}

export function applyTranslations(
    prefix: string,
    translatableFields: Array<Path>,
    payload: Record<string, unknown>,
    translations: Translations
): Record<string, unknown> {
    const newPayload = _.cloneDeep(payload)

    for (const path of translatableFields) {
        const translationKey = getTranslationKey(prefix, path)
        const translationTable = _.get(newPayload, path)
        const latest = translations[translationKey] ?? {}
        // We assign rather than set, in case we're missing any translations,
        // we won't "remove" them from the table
        Object.assign(translationTable, latest)
    }

    return newPayload
}
