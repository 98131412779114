import { createContext, PropsWithChildren, useContext } from 'react'
import classNames from 'classnames'

import { Button, ButtonProps } from './Button'

import style from './ToggleButton.module.scss'

interface ToggleButtonGroupContextData {
    color?: ButtonProps['color']
    onToggle?: (value: string) => void
    value?: string
}

const ToggleButtonGroupContext = createContext<ToggleButtonGroupContextData>({})

export interface ToggleButtonGroupProps extends PropsWithChildren {
    color?: ButtonProps['color']
    onToggle?: (value: string) => void
    value?: string
}

export const ToggleButtonGroup = (props: ToggleButtonGroupProps) => {
    return (
        <ToggleButtonGroupContext.Provider
            value={{
                value: props.value,
                onToggle: props.onToggle,
                color: props.color,
            }}
        >
            <div className={style.group} role="group">
                {props.children}
            </div>
        </ToggleButtonGroupContext.Provider>
    )
}

export type ToggleButtonProps = {
    value?: string
} & ButtonProps

export const ToggleButton = (props: ToggleButtonProps) => {
    const { value, className, onClick, children, ...other } = props
    const groupContext = useContext(ToggleButtonGroupContext)

    return (
        <Button
            onClick={(e) => {
                onClick?.(e)
                if (!e.defaultPrevented && props.value !== undefined) {
                    groupContext.onToggle?.(props.value)
                }
            }}
            className={classNames(
                style.button,
                {
                    [style.active]:
                        value !== undefined &&
                        groupContext.value === props.value,
                },
                className
            )}
            color={groupContext.color}
            {...other}
        >
            {children}
        </Button>
    )
}
